const list_tool = [
    {id:10, data:[
        {id:100, img1:"save", img2:"angle2"},
        {id:101, img1:"print"}
    ]},
    {id:11, data:[
        {id:110, img1:"cut"},
        {id:111, img1:"copy"},
        {id:112, img1:"paste"},
        {id:113, img1:"delete"}  
    ]},
    {id:12, data:[
        {id:120, img1:"undo"},
        {id:121, img1:"redo"}
    
    ]},
    {id:13, data:[
        {id:130, img1:"zoom_in_map"},
        {id:131, img1:"zoom_out_map"},
        {id:132, img1:"zoom-in"},
    
    ]},
    {id:14, data:[
        {id:140, img1:"font-style", img2:"angle2"},
        {id:141, img1:"bold"},
        {id:142, img1:"italic"},
        {id:143, img1:"font-size"},
        {id:144, img1:"pencil"},
        {id:145, img1:"paint"},
        {id:146, img1:"eraser"},
        {id:147, img1:"paint-roller"}
        
    ]},
    {id:15, data:[
        {id:150, img1:"warning"},
        {id:151, img1:"attachment"}
    
    ]},
    {id:16, data:[
        {id:160, img1:"explore", img2:"angle2"}
    ]},

]

export default list_tool;