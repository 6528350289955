import {useState} from 'react'
import { idSvgMap } from '../data/stdClsFnc';
import { useMyContextMn, useMyContextSp } from '../../Master';
import { x_handleMouseDown } from './Sp_ListBCComp_func';

export default function ListBCComp({txt, data}) {

    const {setWrnShow, setWrnId, wrnStyleFunc} = useMyContextSp()
    const {setWrnFlag} = useMyContextMn()

    const handleMouseDown = function(id) {
        x_handleMouseDown(id, setWrnId, setWrnShow, setWrnFlag, wrnStyleFunc)
    }

    const list_c_mod = data.map((elem)=>{
        return(
            <li key = {elem.id} className="list-c" onMouseDown={()=>{handleMouseDown(elem.id)}} ><img src={`/project_svg/listC_svg copy/${idSvgMap[elem.id]}.svg`} alt={elem.id} width="15px"/> <span>{elem.txt}</span></li>
        );
    })

    const [dropShow, setdropShow] = useState(false);

    const dropFunc = ()=>{setdropShow(prevState=>!prevState)}

    const img_tag1 = <div key={data[0].id} className={dropShow ? "list-b show": "list-b"} onClick={dropFunc}><img src="/project_svg/app_svg/angle2.svg" alt="angle" width="10px" /> <span>{txt}</span></div>;
    const img_tag2 = <ul key={data[1].id}>{list_c_mod}</ul>

    const img_tag_array = [img_tag1, dropShow && img_tag2];

    return (
    <li> 
        {img_tag_array}
    </li>
    );
  }
  