import './styles/HeaderStyle.css';
// import { useMyContextHd } from '../Master';

export default function Header() {

  // const {} = useMyContextHd();

  return (
    <div className="title-bar">
      <div className="logo-name">
        <img src="/project_svg/app_svg/zoptim-logo2.png" alt="logo" height="30" />
      </div>

      <div className="process-name">
        <input type="text" placeholder="new process" width="200px" />
      </div>
    </div>
  );
}
