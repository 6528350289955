import { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import { baseLineStyle, calcAngleDegrees, calcDistance, crclHeadStyle, crclPointStyle, infoBoxStyle } from "./Mn_connector_func";
import { mouseDnStimulateParent } from "./Mn_CanvasCompUtil_func";

const ConnectorAnchors2Dummy = forwardRef(
    function (props, ref){

        const {id, lineStyle, handleMouseDown, insertTextFunc, handleTouch} = props;

        const crclTailD_Ref = useRef(null);
        const crclHeadD_Ref = useRef(null);
        const baseLine_Ref = useRef(null);

        let points = useMemo(() => [...lineStyle['pts']], [lineStyle]);

        useImperativeHandle(ref, () => ({
          hdMsDn: (coord, clBkSetFunc) => {
              let refElem = crclHeadD_Ref.current;
              if (refElem) {mouseDnStimulateParent(refElem, coord, clBkSetFunc);};
          },
          tlMsDn: (coord, clBkSetFunc) => {
              let refElem = crclTailD_Ref.current;
              if (refElem) {mouseDnStimulateParent(refElem, coord, clBkSetFunc);};
          },
          bsLnMsDn: (index, coord, clBkSetFunc1, clBkSetFunc2) => {
              let refElem = baseLine_Ref.current;
              if (refElem) {mouseDnStimulateParent(refElem, coord, clBkSetFunc1, clBkSetFunc2);};
          }
        }));        

        let len2 = calcDistance(points[0], points[1])
        let angl2 = calcAngleDegrees(points[0],points[1]);
        let trnsfrm2 =`rotate(${angl2}deg)`;
        
        return (
            <div className="connector-parent" onMouseDown={handleMouseDown} >
              <div className="baselineAnchor baseLineD" id={'baseLineD-' + id + '-0'} ref={baseLine_Ref} style={baseLineStyle(points[0], len2, trnsfrm2)} ></div>
              <div className="crclAnchor crclTailD" id={'crclTailD-' + id + '-0'} ref={crclTailD_Ref} style={crclPointStyle(points[0])} ></div>
              <div className="crclAnchor crclHeadD" id={'crclHeadD-' + id + '-1'} ref={crclHeadD_Ref} onMouseEnter={() => {handleTouch(infoBoxStyle(points[1]))}} onDoubleClick={insertTextFunc} style={crclHeadStyle(points[1], trnsfrm2)} ></div> 
            </div>
    )})

export default ConnectorAnchors2Dummy;
