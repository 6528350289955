import { useCallback, useEffect } from "react";
import { useMyContextMn } from "../Master";
import list_tool from "./data/dataToolBar";
import ToolBarComp from "./subcomponents/Mn_ToolBarComp";
import CanvasComp from './subcomponents/Mn_CanvasComp';
import { removeObject } from "./Main_func";

import './styles/MainStyle.css';

export default function Main() {

    const {globalElements, setGlobalElements, globalLanes, setGlobalLanes, globalElementIdMaps, setGlobalElementIdMaps, globalElementTexts, setGlobalElementTexts, slctdElemId, setSlctdElemId, showDummy, setShowDummy, 
        slctdIdList, setSlctdIdList, showDummyList, setShowDummyList} = useMyContextMn()

    const removeObjectParent = useCallback(()=>{ removeObject(slctdElemId, globalElementIdMaps, globalElementTexts, globalElements, globalLanes, setGlobalElementIdMaps, 
        setGlobalElementTexts, setGlobalElements, setGlobalLanes, showDummy, setShowDummy, setSlctdElemId, slctdIdList, setSlctdIdList, showDummyList, setShowDummyList)}, 
        [slctdElemId, globalElementIdMaps, globalElementTexts, globalElements, globalLanes, setGlobalElementIdMaps, 
            setGlobalElementTexts, setGlobalElements, setGlobalLanes, showDummy, setShowDummy, setSlctdElemId, slctdIdList, setSlctdIdList, showDummyList, setShowDummyList])
    
    const handleSave = ()=>{ console.log('handleSave');}
    const handlePrint = ()=>{ console.log('handlePrint');}
    const handleCut = ()=>{ console.log('handleCut');}
    const handleCopy = ()=>{ console.log('handleCopy');}
    const handlePaste= ()=>{ console.log('handlePaste');}
    const handleDelete = removeObjectParent;
    const handleUndo = ()=>{ console.log('handleUndo');}
    const handleRedo = ()=>{ console.log('handleRedo');}

    const handleKeyDown = useCallback((e)=>{ 
        if (e.key === 'Delete') {
            removeObjectParent()
        }},[removeObjectParent])

    //handling key events
    useEffect(()=>{
        document.addEventListener("keydown", handleKeyDown);
        return (()=>{document.removeEventListener("keydown", handleKeyDown)})
    },[handleKeyDown])

    //function map of maps can be created to send only relevant functions into the lists
    const functionMap = {
        100: handleSave,
        101: handlePrint,
        110: handleCut,
        111: handleCopy,
        112: handlePaste,
        113: handleDelete,
        120: handleUndo,
        121: handleRedo
      };

    //toolbar
    const list_tool_mod = list_tool.map((elem)=>{
        return <ToolBarComp key={elem.id} data={elem.data} functionMap={functionMap}/>
    })

    return (
        <div className="main">

            {/* toolbar */}
            <div className="toolbar">
                {list_tool_mod}
            </div>

            {/* Canvas */}
            <div className="canvas-parent">
                <CanvasComp/>
            </div>

        </div>
    );
  }
  