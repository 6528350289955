const x_handleMouseDown = function(x_id, x_setWrnId, x_setWrnShow, x_setWrnFlag, x_wrnStyleFunc) {

    let flag = false;
    
    function funcMouseMove(e){
        if (!flag) {
            x_setWrnId(x_id); x_setWrnShow(true);             
            if (isTargetPresent(e, "canvas")) {
                newElemFunc(e, x_id, document);                                  
                flag = true;  
                x_setWrnFlag(true);
            }                                  
        }
        x_wrnStyleFunc(e, true);                
    }

    function funcMouseUp(){        
        window.removeEventListener("mousemove", funcMouseMove);
        window.removeEventListener("mouseup", funcMouseUp);
        x_setWrnShow(false)   
    }

    window.addEventListener("mousemove", funcMouseMove);
    window.addEventListener("mouseup", funcMouseUp);
}

function newElemFunc(e, x_id, trgtElem) {        
    // Construct the detail object for the custom event
    const detail = { id: x_id, X: e.clientX, Y: e.clientY };
    
    // Create a new CustomEvent with the specified detail
    const newElemEvent = new CustomEvent("newElem", { detail });

    // Dispatch the custom event on the target element
    trgtElem.dispatchEvent(newElemEvent);
}


function isTargetPresent(e, trgtElem) {
    // Get all elements beneath the mouse pointer
    const elementsBeneath = document.elementsFromPoint(e.clientX, e.clientY);

    // Check if any of the elements have the specified class
    const trgtExists = elementsBeneath.some(element => element.classList.contains(trgtElem));

    // Return whether the target element is present
    return trgtExists;
}

export {x_handleMouseDown}