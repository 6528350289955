import { useState } from "react";
import { useMyContextMn } from "../../Master";
import { IsCnctrFunc } from "../data/stdClsFnc";
import { funcDrag2 } from "./Mn_CanvasComp_func";

export default function SelectedList ({dummyStlUpdaterFunc, setIsResize, setDummyElementStyle }) {

    const {globalElements, setGlobalElements, globalElementsModifier2Parent, setSlctdElemId, slctdIdList, setSlctdIdList, setShowDummy, setShowDummyList} = useMyContextMn()

    const [delta, setDelta] = useState({delX : 0, delY : 0});

    const handleMouseDown = (e) => {
        e.preventDefault(); e.stopPropagation();
        funcDrag2(e, setDelta, globalElementsModifier2Parent, dummyStlUpdaterFunc, slctdIdList, setSlctdIdList, globalElements, setGlobalElements, 
            setIsResize, setDummyElementStyle, setSlctdElemId, setShowDummy, setShowDummyList);
    }

    let htmlSelectedListChild =  slctdIdList.map((key)=>{
        const {glbElemId, style} = globalElements[key];

        let left, top, width, height;

        if (IsCnctrFunc(key)) {
            let { pts } = style;
            let len = pts.length;
            left = (Math.min(pts[0][0], pts[len-1][0]) - 5) + 'px';
            top = (Math.min(pts[0][1], pts[len-1][1]) - 5) + 'px';
            width = (Math.abs(pts[len-1][0] - pts[0][0]) + 10) + 'px';
            height = (Math.abs(pts[len-1][1] - pts[0][1]) + 10) + 'px';
        }
        else {
            ({left, top, width, height} = style);        
        }
            
        left = (Number(left.slice(0,-2)) + delta.delX) + 'px' ;
        top = (Number(top.slice(0,-2)) + delta.delY) + 'px' ;
        
        return(
            <div key={glbElemId} onMouseDown={handleMouseDown} data-id={glbElemId} className='slctdLst dummyObject2 clsvld' style={{left, top, width, height}}> </div>
            // for further improvement monitor clsvld while drag
        );
    })

  return (
    <>{htmlSelectedListChild} </> 
    );
}