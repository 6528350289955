import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { IsActivitsFunc, IsCnctrFunc, IsLaneFunc, IsDataObjFunc, isParentLaneFunc, dummyClassCreator, isSingleLaneFunc, isChildLaneFunc, cnctrType } from '../data/stdClsFnc';
import { dragResizeFunction2 } from './Mn_drag_resize2';
import { dragResizeFunction3 } from './Mn_drag_resize3';
import { useMyContextMn } from '../../Master';
import { afterDragCompleteParentFunc, dummyStlUpdaterChildFunc, x_adjustTxtAreaHt, x_insertTextFunc, x_createTxtBxCopy,
    x_destroyTxtBxCopy, createDummyFunc, globalTextUpdater, x_deleteDummy,resetAllPointsFunc, funcDrag, blinkToolTip,
    x_globalHoversModifier} from './Mn_CanvasComp_func';
import { mouseDnStimulate, drgFnshFunc, laneDummyStylFunc1, laneDummyStylFunc2, laneDummyStylFunc3, dobjctStylFunc1, insertPt } from './Mn_CanvasCompUtil_func';
import CanvasSize from './Mn_CanvasSize';
import SelectedList from './Mn_SelectedList';
import SLaneAnchorComp from './Mn_SLaneAnchorComp';
import SLane from './Mn_SLane';
import { dragResizeFunction4 } from './Mn_drag_resize4';
import SvgIcon from './Mn_ObjectSvg';
import InfoBoxIcon from "./Mn_InfoBoxIcon";
import ConnectorAnchors1 from './Mn_ConnectorAnchors1';
import ConnectorAnchors2 from './Mn_ConnectorAnchors2';
import ConnectorSvg1 from './Mn_ConnectorSvg1';
import ConnectorSvg2 from './Mn_ConnectorSvg2';
import ConnectorAnchors1Dummy from './Mn_ConnectorAnchors1Dummy';
import ConnectorAnchors2Dummy from './Mn_ConnectorAnchors2Dummy';
import ConnectorSvg1Dummy from './Mn_ConnectorSvg1Dummy';
import { dragResizeFunction10 } from './Mn_drag_resize10';
import ConnectorSvg2Dummy from './Mn_ConnectorSvg2Dummy';
import ObjectAnchors from './Mn_ObjectAnchors';
import { dragResizeFunction11 } from './Mn_drag_resize11';


export default function CanvasComp() {

    const {setWrnFlag, globalElements, setGlobalElements, globalElementIdMaps, setGlobalElementIdMaps, globalElementTexts, setGlobalElementTexts, slctdElemId, setSlctdElemId,
        showDummy, setShowDummy, globalElementsUpdater, globalLanesUpdater, globalElementsModifier, globalLanesModifier, globalElementsPointsReset,
        globalElementsIdsReset, setSlctdIdList, showDummyList, setShowDummyList, globalLanes, setGlobalLanes} = useMyContextMn()

    const [globalHovers, setGlobalHovers] = useState({});
    const [dummyElementStyle, setDummyElementStyle] = useState({});
    const [newElemId, setNewElemId] = useState('');
    const [isResize, setIsResize] = useState(false);
    const [mdPosition, setMdPositon] = useState({x:0, y:0}); 
    const dumRef = useRef(null);
    const dumSwimLaneRef = useRef(null);
    const dumSingleLaneRef = useRef(null);
    const IsConnector = IsCnctrFunc(slctdElemId);
    const IsLane = IsLaneFunc(slctdElemId);
    const isSwimLane = isParentLaneFunc(slctdElemId);
    const isChildLane = isChildLaneFunc(slctdElemId);
    let isSingleLaneRsz = false, isSingleLaneLft = false
    if (isSingleLaneFunc(slctdElemId) ) {
        const parts = slctdElemId.split("-"); 
        if (parts[2] === 'Left') { isSingleLaneLft = true; }
        else if (parts[2] === 'Resize') { isSingleLaneRsz = true; }
    }
    const [drgFnsh, setDrgFnsh] = useState(0);
    const [counter, setCounter] = useState(()=>10000+Math.floor(Math.random() * 100));
    const [resetPtSgnal, setResetPtSgnal] = useState(false);
    const [isClsInvld, setClsInvld] = useState(false);//box red on overlap
    const [objectMoveDir, setObjectMoveDir] = useState({});

    //to simulate second click
    const [isCrclHead, setIsCrclHead] = useState(false);
    const [isCrclTail, setIsCrclTail] = useState(false);
    const [isBaseLine, setIsBaseLine] = useState(false);
    const [isCrclPt, setIsCrclPt] = useState(false);
    const [isRect, setIsRect] = useState(false);
    const [cnctnIndx, setCnctnIndx] = useState(null);
    const [isNonCnctnBdy, setIsNonCnctnBdy] = useState(false);
    const [isNonCnctSwimLane, setIsNonCnctSwimLane] = useState(false);
    const [isNonCnctSingleLane, setIsNonCnctSingleLane] = useState(false);
    const [isSlctdLst, setIsSlctdLst] = useState(false);
    const cnctnRef = useRef(-1);


    // input Box
    const textareaRef = useRef(null);
    const [inputTxt, setInputTxt] = useState('');
    const [inputStyle, setInputStyle] = useState({});
    const isDblClkRef = useRef(false);
    const [showIpBx, setShowIpBx] = useState(false);
    // textBox copy
    const txtBxCpRef = useRef(null);
    const [txtCopy, setTxtCopy] = useState('');
    const [stlCopy, setStlCopy] = useState({});
    const [txtBxCpId, setTxtBxCpId] = useState('');
    const [txtBxCpShow, setTxtBxCpShow] = useState(false);
    // tooltip box
    const [toolTipStyle, setToolTipStyle] = useState({});
    const [showToolTip, setShowToolTip] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const timeOutIdRef = useRef(null);
    //object Anchor Point
    const [objectAnchorPtStyle, setObjectAnchorPtStyle] = useState({});
    const [showObjectAnchorPt, setShowObjectAnchorPt] = useState(false);

    //to finally set global state from dummy element 
    const globalElementsModifierParent = useCallback(()=>{
        globalElementsModifier(slctdElemId, dummyElementStyle);
    },[globalElementsModifier, slctdElemId, dummyElementStyle])

    const globalLanesModifierParent = useCallback(()=>{
        globalLanesModifier(slctdElemId, dummyElementStyle);
    },[globalLanesModifier, slctdElemId, dummyElementStyle])

    const globalTextUpdaterParent = useCallback(() => {
        globalTextUpdater(txtBxCpId, inputTxt, inputStyle, setGlobalElementTexts, globalElements, setGlobalLanes);
    },[globalElements, inputTxt, inputStyle, txtBxCpId, setGlobalElementTexts, setGlobalLanes])

    const globalHoversModifier = useCallback( function (id, x_bool){
        x_globalHoversModifier(id, x_bool, setGlobalHovers)
      },[])
    
    const afterDragCompleteFunc = useCallback(()=>{
        // console.log('afterDragCompleteFunc start');
        afterDragCompleteParentFunc (slctdElemId, setShowDummy, newElemId, counter, dummyElementStyle, globalElementsUpdater, globalLanesUpdater,
            setSlctdElemId, setTxtBxCpId, setCounter, IsConnector, IsLane, globalElementsModifierParent, globalLanesModifierParent, globalElementsIdsReset, setGlobalElementIdMaps, setResetPtSgnal)
        // console.log('afterDragCompleteFunc stop');
    }
    , [slctdElemId, setShowDummy, setSlctdElemId, newElemId, counter, dummyElementStyle, globalElementsUpdater, globalLanesUpdater, IsConnector, IsLane, globalElementsModifierParent, globalLanesModifierParent, globalElementsIdsReset, setGlobalElementIdMaps])
    
    // handle newElemEvent
    const handleNewElemEvent = useCallback(function (e) {
        // console.log('handleNewElemEvent start');
        e.stopPropagation(); e.preventDefault();
        (showDummy && IsConnector) && globalElementsModifierParent();
        createDummyFunc (e, setIsResize, setSlctdElemId, setDummyElementStyle, setShowDummy, setCnctnIndx, setIsBaseLine, setIsNonCnctnBdy, 
            setIsNonCnctSwimLane, setMdPositon, setNewElemId, showDummyList, setShowDummyList, setSlctdIdList);
        // console.log('handleNewElemEvent stop');
    },[showDummy, setShowDummy, setSlctdElemId, IsConnector, globalElementsModifierParent, showDummyList, setShowDummyList, setSlctdIdList])

    // insert point function in connector
    const insertPtFunc = useCallback((e, points) => {
        insertPt(e, points, setDummyElementStyle)                    
    },[])
    
    // to update connector points if connected to objects
    useEffect(()=>{
        if (resetPtSgnal) {
            resetAllPointsFunc(globalElementIdMaps, slctdElemId, objectMoveDir, globalElementsPointsReset);
            setResetPtSgnal(false);
        }
    },[globalElementIdMaps, slctdElemId, globalElementsPointsReset, resetPtSgnal, globalElements, objectMoveDir])

    // to listen custom event after drag
    useEffect(()=>{
        document.addEventListener("drgFnsh", afterDragCompleteFunc);
        return (()=>{document.removeEventListener("drgFnsh", afterDragCompleteFunc)})    
    },[afterDragCompleteFunc])
    //to listen to new element being brought to canvas
    useEffect(()=>{
        document.addEventListener("newElem", handleNewElemEvent);
        return (()=>{document.removeEventListener("newElem", handleNewElemEvent)})
    },[handleNewElemEvent])

    //to copy the details of the selected element on canvas so as to send it to dummy element
    const dummyStlUpdaterFunc = useCallback( function (e){
        // console.log("dummyStlUpdaterFunc start");
        e.stopPropagation(); e.preventDefault(); 
        (showDummy && IsConnector) && globalElementsModifierParent();
        dummyStlUpdaterChildFunc(e, setIsCrclHead, setIsCrclTail, setIsBaseLine, setIsCrclPt, setIsRect, setCnctnIndx, setIsNonCnctnBdy, setIsNonCnctSwimLane, setIsNonCnctSingleLane, globalHoversModifier, setIsSlctdLst, slctdElemId, setSlctdElemId, showIpBx, globalTextUpdaterParent,
            setShowIpBx, setTxtBxCpId, globalElements, setGlobalElements, globalLanes, setIsResize, setDummyElementStyle, showDummy, setShowDummy, setMdPositon,
            setSlctdIdList, showDummyList, setShowDummyList);  
        // console.log("dummyStlUpdaterFunc stop");
    }, [IsConnector, slctdElemId, setSlctdElemId, setShowDummy, globalHoversModifier, globalElements, setGlobalElements, globalLanes, showDummy, showIpBx, globalElementsModifierParent, 
        globalTextUpdaterParent, setSlctdIdList, showDummyList, setShowDummyList])   

    // to simulate second mouse down
    useEffect(() => {
        if (isSlctdLst) { setIsSlctdLst(false);}
        else if (isCrclHead) { cnctnRef.current.hdMsDn(mdPosition, setIsCrclHead)}
        else if (isCrclTail) { cnctnRef.current.tlMsDn(mdPosition, setIsCrclTail)}
        else if (isBaseLine && (cnctnIndx !== -1)) { cnctnRef.current.bsLnMsDn(cnctnIndx, mdPosition, setIsBaseLine, setCnctnIndx)}
        else if (isCrclPt && (cnctnIndx !== -1)) {cnctnRef.current.crclPtMsDn(cnctnIndx, mdPosition, setIsCrclPt, setCnctnIndx)}
        else if (isRect && (cnctnIndx !== -1)) {cnctnRef.current.rectMsDn(cnctnIndx, mdPosition, setIsRect, setCnctnIndx)}        
        else if (isNonCnctnBdy) {
            let refElem = dumRef.current; 
            if (!txtBxCpShow && refElem) { mouseDnStimulate(refElem, mdPosition); setIsNonCnctnBdy(false);}
        }
        else if (isNonCnctSwimLane) {
            let refElem = dumSwimLaneRef.current; 
            // console.log(refElem);
            if (!txtBxCpShow && refElem) { mouseDnStimulate(refElem, mdPosition); setIsNonCnctSwimLane(false);}
        }
        else if (isNonCnctSingleLane) {
            let refElem = dumSingleLaneRef.current; 
            // console.log(refElem);
            if (!txtBxCpShow && refElem) { mouseDnStimulate(refElem, mdPosition); setIsNonCnctSingleLane(false);}
        }  
        // console.log(" mousedn event effect stop");
    }, [isCrclHead, isCrclTail, isBaseLine, isCrclPt, isRect, cnctnIndx, isNonCnctnBdy, isNonCnctSwimLane, isNonCnctSingleLane, isSlctdLst, showDummy, txtBxCpShow, mdPosition]);

    // to trigger custom event after drag and run globalElementsModifierParent after drag is finished
    useEffect (()=>{
        // console.log(" drgFnsh event effect start", drgFnsh);
        drgFnsh && drgFnshFunc(document);
        // console.log(" drgFnsh event effect stop");
    },[drgFnsh])

    const dragResizeDummy10 = useCallback(
        e => dragResizeFunction10 (e, slctdElemId, setSlctdElemId, dummyElementStyle, setDummyElementStyle, setDrgFnsh, setWrnFlag, setGlobalElementIdMaps, globalHoversModifier, 
            setObjectAnchorPtStyle, setShowObjectAnchorPt, setIsDragging)
        ,[dummyElementStyle, slctdElemId, setSlctdElemId, setWrnFlag, setGlobalElementIdMaps, globalHoversModifier]);
    const dragResizeDummy11 = useCallback(
        e => dragResizeFunction11 (e, slctdElemId, setSlctdElemId, dummyElementStyle, setDummyElementStyle, setDrgFnsh, setWrnFlag, setGlobalElementIdMaps, globalHoversModifier, 
            setObjectAnchorPtStyle, setShowObjectAnchorPt, setIsDragging)
        ,[dummyElementStyle, slctdElemId, setSlctdElemId, setWrnFlag, setGlobalElementIdMaps, globalHoversModifier]);
    const dragResizeDummy2 = useCallback(
        e => dragResizeFunction2 (e, slctdElemId, setSlctdElemId, dummyElementStyle, setDummyElementStyle, setDrgFnsh, setClsInvld, setWrnFlag, setObjectMoveDir, setIsDragging)
        ,[dummyElementStyle, slctdElemId, setSlctdElemId, setWrnFlag]);
    const dragResizeDummy3 = useCallback(
        e => dragResizeFunction3 (e, slctdElemId, setSlctdElemId, dummyElementStyle, setDummyElementStyle, setDrgFnsh, setClsInvld, setWrnFlag)
        ,[dummyElementStyle, slctdElemId, setSlctdElemId, setWrnFlag]);
    const dragResizeDummy4 = useCallback(
        e => dragResizeFunction4 (e, slctdElemId, setSlctdElemId, dummyElementStyle, setDummyElementStyle, setDrgFnsh, setClsInvld, setWrnFlag)
        ,[dummyElementStyle, slctdElemId, setSlctdElemId, setWrnFlag]);

    //takes care of removing dummy and placing its details back to the original element(for connector)
    const deleteDummy = useCallback((e)=>{
        x_deleteDummy(e, globalElementsModifierParent, globalTextUpdaterParent,showDummy, setShowDummy, setSlctdElemId, showIpBx, setShowIpBx, 
            IsConnector, showDummyList, setShowDummyList, setSlctdIdList)
    }, [IsConnector, globalElementsModifierParent, globalTextUpdaterParent, showDummy, setShowDummy, setSlctdElemId, showIpBx, showDummyList, setShowDummyList, setSlctdIdList]);
    
    // inputBox
    const handleInputChange = (e) => {setInputTxt(textareaRef.current.value);}
    const adjustTxtAreaHt = useCallback(() => { x_adjustTxtAreaHt(textareaRef);},[])

    const insertTextFunc = useCallback((id) => {
        x_insertTextFunc (id, globalElements, globalElementTexts, globalLanes, setInputTxt, setInputStyle, setShowIpBx, textareaRef)                    
    },[globalElements, globalElementTexts, globalLanes])

    // textBox Copy
    const createTxtBxCopy = useCallback((e)=> {
        x_createTxtBxCopy(e, setMdPositon, setTxtBxCpId, insertTextFunc, isDblClkRef, globalElementTexts,
            setStlCopy, globalElements, setTxtCopy, setTxtBxCpShow, setGlobalElementTexts, showIpBx, globalTextUpdaterParent, setShowIpBx);
    },[insertTextFunc, globalElements, globalElementTexts, setGlobalElementTexts, showIpBx, globalTextUpdaterParent])    

    useEffect(() => {
        // console.log(" mousedn event effect start");
        const txtElem = txtBxCpRef.current;
        txtElem && mouseDnStimulate(txtElem, mdPosition);
        // console.log(" mousedn event effect stop");
    }, [txtBxCpShow, mdPosition]);

    const destroyTxtBxCopy = useCallback(()=> {
        x_destroyTxtBxCopy (txtBxCpId, stlCopy, globalElements, setGlobalElementTexts, setTxtBxCpShow); 
    },[globalElements, txtBxCpId, stlCopy, setGlobalElementTexts])

    const dragTxtBxCopy = useCallback((e) => {
        funcDrag(e, stlCopy, setStlCopy);
    },[stlCopy])

    // tooltip
    const blinkToolTipParent = useCallback((style) => {
        blinkToolTip(timeOutIdRef, style, setToolTipStyle, setShowToolTip);
    },[] )

    
    //creating html list of all the objects in globalElements

    let globalElementsIds = useMemo(() => {return (Object.keys(globalElements))}, [globalElements]);

    let htmlObjLst10 = useMemo( () => { return (
        globalElementsIds.filter(x_id=>IsCnctrFunc(x_id)).map((x_id,index)=>{
        const {glbElemId,IsRsz, style} = globalElements[x_id];

        if (!IsRsz) return null; // Early return to avoid rendering if resizing is not allowed

        const x_handleMouseEnter = () => {!isDragging && globalHoversModifier(glbElemId,true)}
        const x_handleMouseLeave = () => {!isDragging && globalHoversModifier(glbElemId,false)}

        const ConnectorComponent = cnctrType(glbElemId) === 0 ? ConnectorAnchors1 : ConnectorAnchors2;
        return(
                <ConnectorComponent key={index} id={glbElemId} lineStyle={style} handleMouseEnter={x_handleMouseEnter} handleMouseLeave={x_handleMouseLeave} handleMouseDown={dummyStlUpdaterFunc} handleTouch={blinkToolTipParent} />
        );    
    }))}, [globalElements, globalElementsIds, dummyStlUpdaterFunc, globalHoversModifier, blinkToolTipParent, isDragging])

    let htmlObjLst11 = useMemo( () => { return (
        globalElementsIds.filter(x_id=>IsCnctrFunc(x_id)).map((x_id,index)=>{
        const {glbElemId,IsRsz, style} = globalElements[x_id]; 
        if (!IsRsz) return null; // Early return to avoid rendering if resizing is not allowed

        const ConnectorComponent = cnctrType(glbElemId) === 0 ? ConnectorSvg1 : ConnectorSvg2;

        return(
            <ConnectorComponent key={index} id={glbElemId} lineStyle={style} isHover={globalHovers[glbElemId] ?? false} />
        );    
    }))}, [globalElements, globalElementsIds, globalHovers])

    let htmlObjLst1TxtBoxes = useMemo( () => { return (
        globalElementsIds.filter(x_id=>IsCnctrFunc(x_id)).map((x_id,index)=>{
        const {glbElemId } = globalElements[x_id];  
        let txt, txtStl, show, txtExists = false;
        if (globalElementTexts[x_id]) {
            txtExists = true;
            ({txt, txtStl, show} = globalElementTexts[x_id]);
        }
        return(
            (txtExists && show) && <div key={index} data-id={glbElemId} className='txtBx' style={txtStl} onMouseDown={createTxtBxCopy} >{txt}</div>
        );    
    }))}, [globalElements, globalElementsIds, globalElementTexts, createTxtBxCopy])

    let htmlObjLst2 = useMemo( () => { return (
        globalElementsIds.filter(x_id=>!IsCnctrFunc(x_id) && !IsLaneFunc(x_id)).map((x_id,index)=>{
        const {glbElemId, style} = globalElements[x_id];
        let isTask = IsActivitsFunc(glbElemId) || IsDataObjFunc(glbElemId);
        let txt, txtStl, show, txtExists;
        if (globalElementTexts[x_id]) {
            txtExists = true;
            ({txt, txtStl, show} = globalElementTexts[x_id]);
        }
        return(
            <div key={index} id= {glbElemId} onMouseDown={dummyStlUpdaterFunc} className={isTask?'dobjct dtask':'dobjct'} style={dobjctStylFunc1(style)}>
                {(txtExists && isTask) && <div data-id={glbElemId} className='taskBx'>{txt}</div>}
                {(txtExists && !isTask && show) && <div data-id={glbElemId} className='txtBx' style={txtStl} onMouseDown={createTxtBxCopy} >{txt}</div>}                
            </div>
        );
    }))}, [globalElements, globalElementsIds, dummyStlUpdaterFunc, globalElementTexts, createTxtBxCopy])

    let htmlObjLst2Svg  = useMemo( () => { return (
        globalElementsIds.filter(x_id=>!IsCnctrFunc(x_id) && !IsLaneFunc(x_id)).map((x_id,index)=>{
        const {glbElemId, style} = globalElements[x_id];        
        return(
            <SvgIcon key={index} itemid={glbElemId} style={style} isHover={globalHovers[glbElemId] ?? false}/>
        );
    }))}, [globalElements, globalElementsIds, globalHovers])

    let htmlObjLst2Anchors  = useMemo( () => { return (
        globalElementsIds.filter(x_id=>!IsCnctrFunc(x_id) && !IsLaneFunc(x_id)).map((x_id,index)=>{
        const {glbElemId, style} = globalElements[x_id];        
        return(
            <ObjectAnchors key={index} itemid={glbElemId} style={style} />
        );
    }))}, [globalElements, globalElementsIds])

    let globalLAnesIds = useMemo(() => {return (Object.keys(globalLanes))}, [globalLanes])

    const htmlObjLst3 = useMemo(() => { return (globalLAnesIds?.filter(x_id=>isParentLaneFunc(x_id)).map((x_id,index) => {
        return <SLane key={index} x_id={x_id} slane={globalLanes[x_id]}/>
    }))},[globalLAnesIds, globalLanes]);
    const htmlObjLst4 = useMemo(() => {return (globalLAnesIds?.filter(x_id=>isParentLaneFunc(x_id)).map((x_id,index) => {
        return <SLaneAnchorComp key={index} x_id={x_id} slane={globalLanes[x_id] } insertTextFunc={insertTextFunc} handleMouseDown={dummyStlUpdaterFunc}/>
    }))}, [globalLAnesIds, globalLanes, dummyStlUpdaterFunc, insertTextFunc]);

    //creating html for dummy element on canvas
    let htmlDummyElement10 = IsConnector && showDummy && ( cnctrType(slctdElemId)===0 ? 
        <ConnectorAnchors1Dummy ref={cnctnRef} id={slctdElemId} lineStyle={dummyElementStyle} handleMouseDown={dragResizeDummy10} insertPtFunc={insertPtFunc} insertTextFunc={insertTextFunc} handleTouch={blinkToolTipParent} /> :
        <ConnectorAnchors2Dummy ref={cnctnRef} id={slctdElemId} lineStyle={dummyElementStyle} handleMouseDown={dragResizeDummy11} insertTextFunc={insertTextFunc} handleTouch={blinkToolTipParent} />
        )
    let htmlDummyElement11 = IsConnector && showDummy && ( cnctrType(slctdElemId)===0 ? 
        <ConnectorSvg1Dummy id={slctdElemId} lineStyle={dummyElementStyle} /> :
        <ConnectorSvg2Dummy id={slctdElemId} lineStyle={dummyElementStyle} />
        )
    let htmlDummyElement2 = !IsConnector && !IsLane && showDummy && <div ref={dumRef} onMouseDown={dragResizeDummy2} onDoubleClick={()=>insertTextFunc(slctdElemId)} className={dummyClassCreator(20,isClsInvld)} style={dummyElementStyle}>
            {isResize && <div className="drsz"></div>}
        </div>;
    let htmlDummyElement3 = isSwimLane && showDummy &&  <>
            <div ref={dumSwimLaneRef} onMouseDown={dragResizeDummy3} onDoubleClick={()=>insertTextFunc(slctdElemId)} className={dummyClassCreator(30,isClsInvld)} style={laneDummyStylFunc1(dummyElementStyle)}></div>
            <div className={dummyClassCreator(31,isClsInvld)} style={laneDummyStylFunc2(dummyElementStyle)} ></div>
        </>
    let htmlDummyElement4 = isSingleLaneRsz && showDummy &&  <div ref={dumSingleLaneRef} onMouseDown={dragResizeDummy4} className={dummyClassCreator(32,isClsInvld)} style={laneDummyStylFunc3(dummyElementStyle)}><div className='resizeLaneAnchorDummy'></div></div>
    let htmlDummyElement5 = (isChildLane || isSingleLaneLft) && showDummy &&  <div className={dummyClassCreator(31,false)} style={laneDummyStylFunc3(dummyElementStyle)}></div>
        
    // creating html for inputbox and textboxcopy on canvas
    let isTaskBool = IsActivitsFunc(txtBxCpId) || IsDataObjFunc(txtBxCpId); 
    let htmlInputBox = showIpBx && <div className='inputBox' style={inputStyle} onMouseDown={e=>e.stopPropagation()}>
        <textarea ref={textareaRef} rows={isTaskBool?4:1} value={inputTxt} onChange={handleInputChange} onKeyDown={adjustTxtAreaHt}/>
        </div>
    let htmlTextBoxCopy = txtBxCpShow && <div ref={txtBxCpRef} className='txtBxCopy' style={stlCopy} onMouseDown={dragTxtBxCopy} onMouseUp={destroyTxtBxCopy} >{txtCopy}</div>
    let htmlToolTip = <div className="info-box" style={toolTipStyle}><InfoBoxIcon/></div>
    let htmlObjectAnchorPt = <div className="objectAnchorPtClass" style={objectAnchorPtStyle}></div>

    return (
        <div onMouseDown={deleteDummy} id = "canvas" className="canvas">
            {/* place where the bpmn process model develops */}
            {htmlObjLst2}
            {htmlObjLst2Anchors}
            {htmlObjLst2Svg}
            {htmlObjLst4}
            {isSwimLane && htmlDummyElement3}
            {isSingleLaneRsz && htmlDummyElement4}
            {(isChildLane || (isSingleLaneLft)) && htmlDummyElement5}
            {!IsConnector && !IsLane && htmlDummyElement2}    
            <svg className='svgLanesClass' width="100%" height="100%" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
            {htmlObjLst3}
            </svg>        
            <svg id="connectors" className='connectorsClass' width="100%" height="100%" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
            {/* {htmlObjLst1} */}
            {htmlObjLst11}
            {/* why htmlobjlst3 here */}
            {htmlObjLst3}
            {/* {IsConnector && htmlDummyElement1} */}
            {IsConnector && htmlDummyElement11}
            </svg>
            {showDummyList && <SelectedList dummyStlUpdaterFunc={dummyStlUpdaterFunc} setIsResize={setIsResize} setDummyElementStyle={setDummyElementStyle}/>}
            {htmlObjLst1TxtBoxes}
            {/* {htmlObjLst1HdTl} */}
            {htmlObjLst10}
            {/* {IsConnector && htmlDummyElement1HdTl} */}
            {IsConnector && htmlDummyElement10}
            {showIpBx && htmlInputBox}
            {txtBxCpShow && htmlTextBoxCopy}
            {!isDragging && showToolTip && htmlToolTip}
            {showObjectAnchorPt && htmlObjectAnchorPt}
            {/* only for testing*/}
            <button className='testButon' onMouseDown={(e)=>{e.stopPropagation(); e.preventDefault(); console.log(globalElements);}} >test</button>
            <CanvasSize/>
        </div>
    )
  }
  