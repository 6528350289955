import { discretize5 } from "./Mn_CanvasCompUtil_func";

const generateChildBoxesById = (id, width, height) => {
    const innerX = 5;
    const innerY = 5;
    const innerWidth = width - 10;
    const innerHeight = height - 10;

    // Calculate vertical and horizontal dividing lines, TGE: task-gateway-events, DA: data objects-artifacts
    const verticalLinesTGE = [
        innerX + Math.round(innerWidth / 3),
        Math.round(width / 2),
        innerX + Math.round((2 * innerWidth) / 3),
    ];
    const horizontalLinesTGE = [
        innerY + Math.round(innerHeight / 9),
        innerY + Math.round(innerHeight / 3),
        innerY + Math.round((2 * innerHeight) / 3),
        innerY + Math.round((8 * innerHeight) / 9),
    ];

    const recDiff = [
      Math.round(innerWidth / 3),
      Math.round((2 * innerHeight) / 9),
      Math.round(innerHeight / 3),
    ];

    const verticalLinesDA = [
        Math.round(width / 3),
        Math.round(width / 2),
        Math.round((2 * width) / 3),
    ];
    const horizontalLinesDA = [
        Math.round(height / 3),
        Math.round(height / 2),
        Math.round((2 * height) / 3),
    ];

    // Calculating border points
    const borderPoints = {
        A0: [discretize5(innerX + innerWidth / 6), innerY],
        A1: [discretize5(innerX + innerWidth / 2), innerY],
        A2: [discretize5(innerX + (5 * innerWidth) / 6), innerY],
        B0: [innerX + innerWidth, discretize5(innerY + innerHeight / 6)],
        B1: [innerX + innerWidth, discretize5(innerY + innerHeight / 2)],
        B2: [innerX + innerWidth, discretize5(innerY + (5 * innerHeight) / 6)],
        C0: [discretize5(innerX + innerWidth / 6), innerY + innerHeight],
        C1: [discretize5(innerX + innerWidth / 2), innerY + innerHeight],
        C2: [discretize5(innerX + (5 * innerWidth) / 6), innerY + innerHeight],
        D0: [innerX, discretize5(innerY + innerHeight / 6)],
        D1: [innerX, discretize5(innerY + innerHeight / 2)],
        D2: [innerX, discretize5(innerY + (5 * innerHeight) / 6)],
        E0: [innerX, innerY],
        E1: [innerX + innerWidth, innerY],
        E2: [innerX + innerWidth, innerY + innerHeight],
        E3: [innerX, innerY + innerHeight],
    };

// Define different sets of child boxes based on requirements
const getChildBoxes12 = () => [
  { id: 'A00', borderReference: borderPoints.A0, x: 0, y: 0, w: verticalLinesTGE[0], h: horizontalLinesTGE[0] },
  { id: 'A11', borderReference: borderPoints.A1, x: verticalLinesTGE[0], y: 0, w: recDiff[0], h: horizontalLinesTGE[1] },
  { id: 'A22', borderReference: borderPoints.A2, x: verticalLinesTGE[2], y: 0, w: verticalLinesTGE[0], h: horizontalLinesTGE[0] },
  { id: 'B00', borderReference: borderPoints.B0, x: verticalLinesTGE[2], y: horizontalLinesTGE[0], w: verticalLinesTGE[0], h: recDiff[1] },
  { id: 'B11', borderReference: borderPoints.B1, x: verticalLinesTGE[1], y: horizontalLinesTGE[1], w: verticalLinesTGE[1], h: recDiff[2] },
  { id: 'B22', borderReference: borderPoints.B2, x: verticalLinesTGE[2], y: horizontalLinesTGE[2], w: verticalLinesTGE[0], h: recDiff[1] },
  { id: 'C00', borderReference: borderPoints.C0, x: 0, y: horizontalLinesTGE[3], w: verticalLinesTGE[0], h: horizontalLinesTGE[0] },
  { id: 'C11', borderReference: borderPoints.C1, x: verticalLinesTGE[0], y: horizontalLinesTGE[2], w: recDiff[0], h: horizontalLinesTGE[1] },
  { id: 'C22', borderReference: borderPoints.C2, x: verticalLinesTGE[2], y: horizontalLinesTGE[3], w: verticalLinesTGE[0], h: horizontalLinesTGE[0] },
  { id: 'D00', borderReference: borderPoints.D0, x: 0, y: horizontalLinesTGE[0], w: verticalLinesTGE[0], h: recDiff[1] },
  { id: 'D11', borderReference: borderPoints.D1, x: 0, y: horizontalLinesTGE[1], w: verticalLinesTGE[1], h: recDiff[2] },
  { id: 'D22', borderReference: borderPoints.D2, x: 0, y: horizontalLinesTGE[2], w: verticalLinesTGE[0], h: recDiff[1] },
];

const getChildBoxes8 = () => [
  { id: 'E00', borderReference: borderPoints.E0, x: 0, y: 0, w: verticalLinesDA[0], h: horizontalLinesDA[0] },
  { id: 'A11', borderReference: borderPoints.A1, x: verticalLinesDA[0], y: 0, w: verticalLinesDA[0], h: horizontalLinesDA[0] },
  { id: 'E11', borderReference: borderPoints.E1, x: verticalLinesDA[2], y: 0, w: verticalLinesDA[0], h: horizontalLinesDA[0] },
  { id: 'D00', borderReference: borderPoints.D0, x: 0, y: horizontalLinesDA[0], w: verticalLinesDA[1], h: horizontalLinesDA[0] },
  { id: 'B00', borderReference: borderPoints.B0, x: verticalLinesDA[1], y: horizontalLinesDA[0], w: verticalLinesDA[1], h: horizontalLinesDA[0] },
  { id: 'E33', borderReference: borderPoints.E3, x: 0, y: horizontalLinesDA[2], w: verticalLinesDA[0], h: horizontalLinesDA[0] },
  { id: 'C11', borderReference: borderPoints.C1, x: verticalLinesDA[0], y: horizontalLinesDA[2], w: verticalLinesDA[0], h: horizontalLinesDA[0] },
  { id: 'E22', borderReference: borderPoints.E2, x: verticalLinesDA[2], y: horizontalLinesDA[2], w: verticalLinesDA[0], h: horizontalLinesDA[0] },
];

const getChildBoxes6 = () => [
  { id: 'E00', borderReference: borderPoints.E0, x: 0, y: 0, w: verticalLinesDA[0], h: horizontalLinesDA[1] },
  { id: 'A11', borderReference: borderPoints.A1, x: verticalLinesDA[0], y: 0, w: verticalLinesDA[1], h: horizontalLinesDA[1] },
  { id: 'E11', borderReference: borderPoints.E1, x: verticalLinesDA[2], y: 0, w: verticalLinesDA[0], h: horizontalLinesDA[1] },
  { id: 'E33', borderReference: borderPoints.E3, x: 0, y: horizontalLinesDA[1], w: verticalLinesDA[0], h: horizontalLinesDA[1] },
  { id: 'C11', borderReference: borderPoints.C1, x: verticalLinesDA[0], y: horizontalLinesDA[1], w: verticalLinesDA[1], h: horizontalLinesDA[1] },
  { id: 'E22', borderReference: borderPoints.E2, x: verticalLinesDA[2], y: horizontalLinesDA[1], w: verticalLinesDA[0], h: horizontalLinesDA[1] },
];

const getChildBoxes4 = () => [
  { id: 'A10', borderReference: borderPoints.A1, x: 10, y: 0, w: verticalLinesTGE[0]-10, h: horizontalLinesTGE[0] },
  { id: 'A11', borderReference: borderPoints.A1, x: verticalLinesTGE[0], y: 0, w: recDiff[0], h: horizontalLinesTGE[1] },
  { id: 'A12', borderReference: borderPoints.A1, x: verticalLinesTGE[2], y: 0, w: verticalLinesTGE[0]-10, h: horizontalLinesTGE[0] },
  { id: 'B10', borderReference: borderPoints.B1, x: verticalLinesTGE[2], y: horizontalLinesTGE[0], w: verticalLinesTGE[0], h: recDiff[1] },
  { id: 'B11', borderReference: borderPoints.B1, x: verticalLinesTGE[1], y: horizontalLinesTGE[1], w: verticalLinesTGE[1], h: recDiff[2] },
  { id: 'B12', borderReference: borderPoints.B1, x: verticalLinesTGE[2], y: horizontalLinesTGE[2], w: verticalLinesTGE[0], h: recDiff[1] },
  { id: 'C10', borderReference: borderPoints.C1, x: 10, y: horizontalLinesTGE[3], w: verticalLinesTGE[0]-10, h: horizontalLinesTGE[0] },
  { id: 'C11', borderReference: borderPoints.C1, x: verticalLinesTGE[0], y: horizontalLinesTGE[2], w: recDiff[0], h: horizontalLinesTGE[1] },
  { id: 'C12', borderReference: borderPoints.C1, x: verticalLinesTGE[2], y: horizontalLinesTGE[3], w: verticalLinesTGE[0]-10, h: horizontalLinesTGE[0] },
  { id: 'D10', borderReference: borderPoints.D1, x: 0, y: horizontalLinesTGE[0], w: verticalLinesTGE[0], h: recDiff[1] },
  { id: 'D11', borderReference: borderPoints.D1, x: 0, y: horizontalLinesTGE[1], w: verticalLinesTGE[1], h: recDiff[2] },
  { id: 'D12', borderReference: borderPoints.D1, x: 0, y: horizontalLinesTGE[2], w: verticalLinesTGE[0], h: recDiff[1] },
];


    switch (Math.floor((id / 10) % 10)) {
        case 0:
            return getChildBoxes12();
        case 1:
        case 5:
        case 6:
        case 7:
            return getChildBoxes4();
        case 3:
        case 4:
            switch (id % 10) {
                case 0:
                    return getChildBoxes8();
                case 1:
                case 2:
                    return getChildBoxes6();
                default:
                    return [];
            }
        default:
            return [];
    }
};

export default generateChildBoxesById;