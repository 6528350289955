import { idSvgMap, IsCnctrFunc, StyleParam, GlobalElement, singleLaneProps, swimLanePropsA, isParentLaneFunc, isSingleLaneFunc, swimLanePropsB} from './components/data/stdClsFnc';
import { list_c } from './components/data/dataSidePanel';
import { findLaneLength, findParentId, isMoveSimpleFunc, separateLaneIds } from './components/subcomponents/Mn_CanvasCompUtil_func';
import { reCreateArrowPoints, setNewPoint } from './components/subcomponents/Mn_connectorUtil_func';
import { simplifyArrayFunc } from './components/subcomponents/Mn_SingleConnector_func';

// Helper function to validate type
function isValidString(id) {
    return typeof id === 'string' && id.trim() !== '' ;
}

function isValidObject(object) {
    return typeof object === 'object' || object === null ;
}



const x_wrnStyleFunc = (e, x_flag, x_setWrnStyle) => {
    // Set left and top positions based on mouse event coordinates
    const left = e.clientX + 'px';
    const top = e.clientY - 30 + 'px';

    // Determine color and background color based on the flag
    const color = x_flag ? 'darkRed' : 'lightGray';
    const backgroundColor = x_flag ? 'lightGray' : 'darkRed';

    // Set warning style object
    const warningStyle = { left, top, color, backgroundColor };

    // Call the provided setter function to update the warning style
    x_setWrnStyle(warningStyle);
};

const getTextById = function getTextById(id) {
    // Extract the prefix from the id
    const prefix = parseInt(id.toString().substring(0, 2), 10);
    
    // Find the corresponding data array based on the prefix
    const dataArr = list_c.find(item => item.id === prefix)?.data || [];

    // Find the item in the data array with the given id
    const foundItem = dataArr.find(listItem => listItem.id === id);

    // Return the text if the item is found, otherwise return null
    return foundItem ? foundItem.txt : null;
};

const x_panelNdCanvasSizeFunc = (x_collapsePanel, x_widthCounter, x_heightCounter)=>{
  return {
    '--panel-start' :  `${x_collapsePanel?'-200px':'0px'}`,
    '--canvas-width' :  `calc(100% + ${x_widthCounter * 100}px)`,
    '--canvas-height' :  `calc(100% + ${x_heightCounter * 100}px)`
  };
}

// Function to determine direction based on pt0 and pt1 coordinates
function calculateDirection(pt0, pt1) {
    if (pt0[0] > pt1[0]) {
        return '-D1';
    } else if (pt0[0] < pt1[0]) {
        return '-B1';
    } else if (pt0[1] > pt1[1]) {
        return '-A1';
    } else if (pt0[1] < pt1[1]) {
        return '-C1';
    }
    return ''; // Default empty string if no direction is determined
}

const x_globalElementsUpdater = function (x_id, x_style, x_setGlobalElements) {
  // Check if x_id is a non-empty string
  if (!isValidString(x_id)) {
    console.error('Invalid id provided:', x_id);
    return; // Exit the function early if id is invalid
  }

  // Check if x_style is an object
  if (!isValidObject(x_style)) {
    console.error('Invalid style object provided:', x_style);
    return; // Exit the function early if style is invalid
  }
  
  // Extract numeric part of the id
  const numericId = Number(x_id.slice(0, 3));
  // Determine if element needs resizing
  const isResizeable = [10, 12, 13, 14].includes(Math.floor(numericId / 10));
  
  let newStyle, globalElement;

  if (IsCnctrFunc(x_id.toString())) {
      // Preserve the style if it's for a connector function
      newStyle = { ...x_style };
  } else {
      // Generate background image URL based on id
      const backgroundImg = `url(/project_svg/listC_svg/${idSvgMap[numericId].trim()}.svg)`;
      // Create a new style object
      const { left, top, width, height } = x_style;
      newStyle = new StyleParam(left, top, width, height, backgroundImg);
  }

  // Create a new GlobalElement object
  globalElement = new GlobalElement(x_id, isResizeable, newStyle);

  // Update globalElements state
  x_setGlobalElements((prevState) => {
      // Merge the new element into the previous state
      return { ...prevState, [x_id]: globalElement };
  });
};

const x_globalLanesUpdater = function (x_id, x_style, x_setCounter, x_setGlobalLanes) {
    // Check if x_id is a non-empty string
    if (!isValidString(x_id)) {
      console.error('Invalid id provided:', x_id);
      return; // Exit the function early if id is invalid
    }
  
    // Check if x_style is an object
    if (typeof x_style === 'undefined' || x_style === null) {
      console.error('Invalid style object provided:', x_style);
      return; // Exit the function early if style is invalid
    }
    
    if (x_id.slice(0,8) === 'newLane1') {
        const parts = x_id.split("-");
        // console.log(parts);
        let len = parts.length;
        if (len === 3) {
            x_setGlobalLanes((prevState) => {
                // Create a new object to avoid mutating prevState
                const newState = { ...prevState };
                let slctLaneId = parts[2];
                let slctLaneIds = [...prevState[slctLaneId].laneIds]
                let newCLane = new swimLanePropsB( 'swimlane', slctLaneIds);
                let newId = '125Csl' + parts[1];
                newState[slctLaneId] = { ...newState[slctLaneId], laneIds:[newId] };
                newState[newId] = newCLane;
                // Return the updated state
                return newState;
            });

        } else if (len === 4) {
            x_setGlobalLanes((prevState) => {
                // Create a new object to avoid mutating prevState
                const newState = { ...prevState };
                let newLane = new singleLaneProps( 'lane', 300, []);
                let newId = '129Lll' + parts[1];
                let slctLaneId = parts[2];
                let parentLaneId = findParentId(slctLaneId, prevState);
                let parentLaneIds = [...prevState[parentLaneId].laneIds]
                let index = parentLaneIds.indexOf(slctLaneId);
                    if (index !== -1) { 
                        if (parts[3] === 'Up') { parentLaneIds.splice(index, 0, newId)}
                        else if (parts[3] === 'Down') { parentLaneIds.splice(index+1, 0, newId) }
                    }
                    newState[parentLaneId] = { ...newState[parentLaneId], laneIds:parentLaneIds };
                    newState[newId] = newLane;
                // Return the updated state
                return newState;
            });
            
        } else if (len === 5 ) {
            let slctLaneId = parts[2];
            let parentLaneId = parts[3];

            if ( parts[4] === 'Left' || parts[4] === 'Body') {
                x_setGlobalLanes((prevState) => {
                    // Create a new object to avoid mutating prevState
                    const newState = { ...prevState };                
                    let newCLane = new swimLanePropsB( 'swimlane', [slctLaneId]);
                    let newId = '125Csl' + parts[1];
                    let parentLaneIds = [...prevState[parentLaneId].laneIds];
                    let index = parentLaneIds.indexOf(slctLaneId);
                    if (index !== -1) { parentLaneIds[index] = newId}
                    newState[parentLaneId] = { ...newState[parentLaneId], laneIds:parentLaneIds };
                    newState[newId] = newCLane;
                    // Return the updated state
                    return newState;
                });
                
            } else {
                x_setGlobalLanes((prevState) => {
                    // Create a new object to avoid mutating prevState
                    const newState = { ...prevState };                
                    let newLane = new singleLaneProps( 'lane', 300, []);
                    let newId = '129Lll' + parts[1];
                    let parentLaneIds = [...prevState[parentLaneId].laneIds];
                    let index = parentLaneIds.indexOf(slctLaneId);
                    if (index !== -1) { 
                        if (parts[4] === 'Up') { parentLaneIds.splice(index, 0, newId)}
                        else if (parts[4] === 'Down') { parentLaneIds.splice(index+1, 0, newId) }
                    }
                    newState[parentLaneId] = { ...newState[parentLaneId], laneIds:parentLaneIds };
                    newState[newId] = newLane;
                    // Return the updated state
                    return newState;
                });                
            }
        }
        x_setCounter(parseFloat(parts[1]) + Math.floor(Math.random() * 100));

    } else {
        let {left, top, width, height} = x_style;
        let position = [parseFloat(left), parseFloat(top)];
        let lenght = parseFloat(width) - 20;
        // 120Poo : swimlane, 125Csl : child swimlane, 129Lll : lane
        let laneId = '129Lll';
        let newCount = Number(x_id.slice(6));
        newCount += Math.floor(Math.random() * 100);
        laneId += newCount;
        newCount += Math.floor(Math.random() * 100);
        x_setCounter(newCount);

        let newLane = new singleLaneProps('lane', parseFloat(height), []);
        let newSLane = new swimLanePropsA( 'swimlane', position, lenght, [laneId]);

        // Update globalElements state
        x_setGlobalLanes((prevState) => {
            // Merge the new element into the previous state
            return { ...prevState, [x_id]: newSLane, [laneId]: newLane };
        });
    }
  };

const x_globalElementsModifier = function (x_id, x_style, x_setGlobalElements) {
  // Check if id is a non-empty string
  if (!isValidString(x_id)) {
      console.error('Invalid id provided:', x_id);
      return; // Exit the function early if id is invalid
  }

  // Check if style is an object
  if (!isValidObject(x_style)) {
      console.error('Invalid style object provided:', x_style);
      return; // Exit the function early if style is invalid
  }

  // Update globalElements state
  x_setGlobalElements((prevState) => {
      // Create a new object to avoid mutating prevState
      const newState = { ...prevState };
      
      // Check if the element is a connector function
      if (IsCnctrFunc(x_id)) {
          // For connector functions, update style and IsRsz flag
          newState[x_id] = { ...newState[x_id], style: { ...x_style }, IsRsz: true };
      } else {
          // For other elements, update style without modifying backgroundImage
          const prevStyle = prevState[x_id].style;
          newState[x_id] = { ...newState[x_id], style: { ...x_style, backgroundImage: prevStyle.backgroundImage } };
      }
      
      // Return the updated state
      return newState;
  });
};

const x_globalLanesModifier = function (x_id, x_style, x_setGlobalLanes) {
    // Check if id is a non-empty string
    if (!isValidString(x_id)) {
        console.error('Invalid id provided:', x_id);
        return; // Exit the function early if id is invalid
    }
  
    // Check if style is an object
    if (!isValidObject(x_style)) {
        console.error('Invalid style object provided:', x_style);
        return; // Exit the function early if style is invalid
    }
  
    // Update globalElements state
    x_setGlobalLanes((prevState) => {
        // Create a new object to avoid mutating prevState
        const newState = { ...prevState };

        if (isParentLaneFunc(x_id)) {
            let {left, top, width} = x_style;
            let position = [parseFloat(left), parseFloat(top)];
            let length = parseFloat(width) - 20;        
            newState[x_id] = { ...newState[x_id], position, length };
        } else if (isSingleLaneFunc(x_id)) {
            let {width:laneWd, height:laneHt} = x_style;
            let { laneId, superId } = separateLaneIds(x_id);
            // create type checks here and in the following line *** for later improvements
            let {resultId, resultCount} = findLaneLength(superId, prevState);
            newState[laneId] = { ...newState[laneId], height: parseFloat(laneHt) };
            let length = parseFloat(laneWd) + (resultCount * 20);
            newState[resultId] = { ...newState[resultId], length };
        }
               
        // Return the updated state
        return newState;
    });
  };

  const x_globalElementsPointsReset = function(x_id, x_objMvDir, x_idxs, x_setGlobalElements) {
    // Check if id is a non-empty string
    if (!isValidString(x_id)) {
        console.error('Invalid id provided:', x_id);
        return; // Exit the function early if id is invalid
    }
  
    if (!IsCnctrFunc(x_id)) {
      console.error('Not a connector:', x_id);
      return; // Exit the function early if its not a connector
      }
  
    // Update globalElements state
    x_setGlobalElements((prevState) => {
        // Create a new object to avoid mutating prevState
        const newState = { ...prevState };

        // Retrieve style information
        const elementStyle = newState[x_id]?.style;
        if (!elementStyle) {
            console.error('Invalid style object for element:', x_id);
            return prevState; // Exit if style object is missing
        }

        let ids = [...elementStyle.ids];
        let pts = elementStyle.pts.map(pt => [...pt]);
        const {delX:moveX, delY:moveY} = {...x_objMvDir};
        const len = pts.length;
        const isHd = x_idxs === 'idfs';

        let points ;
  
        // Update pts using the updatePoints function
        let superId = isHd ? ids[1] : ids[0];
        let dropDir = superId?.split('-')[1].slice(0,1) ?? null;
        superId = 'objectId-' + superId +  superId.charAt(superId.length - 1);
        let targetElement = document.getElementById(superId);

        if (!targetElement || !targetElement.dataset.borderpt) {
            console.error('Invalid target element or missing border point for:', superId);
            return prevState; // Exit early if target element or dataset is invalid
        }

        const borderpt = targetElement.dataset.borderpt;
        const dropPt = borderpt?.split('-').map(parseFloat) ?? null;

        const otherId = isHd ? ids[0] : ids[1];
        const otherDir = otherId?.split('-')[1]?.slice(0, 1) ?? null;

        // Determine movement type
        const isMoveSimple = isMoveSimpleFunc(dropDir, moveX, moveY, pts[isHd ? len - 1 : 0], pts[isHd ? len - 2 : 1]);

        console.log(isMoveSimple);

        if( len > 2 && (isMoveSimple)) {
            points = setNewPoint(isHd, pts, pts[isHd ? len - 1 : 0], dropDir, dropDir, dropPt);
            points = simplifyArrayFunc(points);
        } else {
            const headPoint = (isHd && dropPt) ? dropPt : [...pts[len - 1]];
            const tailPoint = (!isHd && dropPt) ? dropPt : [...pts[0]];
            const isInSequence = tailPoint[0] <= headPoint[0];
            const headDirection = isHd
                ? dropDir ?? (isInSequence ? 'D' : 'B')
                : otherDir?.slice(0, 1) ?? (isInSequence ? 'D' : 'B');
            const tailDirection = isHd
                ? otherDir ?? (isInSequence ? 'B' : 'D')
                : dropDir?.slice(0, 1) ?? (isInSequence ? 'B' : 'D');
            points = reCreateArrowPoints(tailPoint, headPoint, tailDirection, headDirection);
        }
  
        // Update the style object with the new pts
        newState[x_id] = { ...newState[x_id], style: { ids, pts: points } };
  
        // Return the updated state
        return newState;
    });
  };

const x_globalElementsIdsReset = function (x_cnctrId, x_isHd, x_elemId, x_setGlobalElements) {
  // Check if x_cnctrId is a non-empty string
  if (!isValidString(x_cnctrId)) {
      console.error('Invalid connector id provided:', x_cnctrId);
      return; // Exit the function early if x_cnctrId is invalid
  }

  // Check if x_isHd is a boolean
  if (typeof x_isHd !== 'boolean') {
      console.error('isHd should be a boolean value:', x_isHd);
      return; // Exit the function early if x_isHd is not a boolean
  }

  // Check if x_elemId is a non-empty string
  if (!isValidString(x_elemId)) {
      console.error('Invalid element id provided:', x_elemId);
      return; // Exit the function early if x_elemId is invalid
  }

  // Update globalElements state
  x_setGlobalElements((prevState) => {
      // Create a new object to avoid mutating prevState
      const newState = { ...prevState };

      // Retrieve ids and pts from the style object of the specified element
      let ids = [...newState[x_cnctrId].style.ids]; 
      let pts = newState[x_cnctrId].style.pts.map(pt => [...pt]);

      let prevIds = ids.map(id => id?.split('-')[0])
      if (prevIds.includes(x_elemId)) { 
        console.warn('dropped on existing connector:', x_cnctrId);
        return prevState; // Return previous state if connector already is attached to the object
      }

      const len = pts.length;     

      const pt0 = pts[x_isHd ? len - 1 : 0];
      const pt1 = pts[x_isHd ? len - 2 : 1];

      // Determine direction using calculateDirection function which gives dashed direction eg. '-C1'
      const dir = calculateDirection(pt0, pt1);

      // Append direction to x_elemId to form id_dir
      const id_dir = x_elemId + dir;

      // Update the corresponding id based on x_isHd index
      ids[x_isHd ? 1 : 0] = id_dir;

      // Update the style object with the new ids
      newState[x_cnctrId] = { ...newState[x_cnctrId], style: { ids, pts } };

      // Return the updated state
      return newState;
  });
};

const x_globalElementsModifier2 = function (x_idList, x_delta, x_setGlobalElements) {
    // Check if idList is an array
    if (!Array.isArray(x_idList)) {
        console.error('idList should be an array:', x_idList);
        return; // Exit the function early if idList is not an array
    }

    // Check if delta is an object with expected properties
    if (typeof x_delta !== 'object' || x_delta === null || !('delX' in x_delta) || !('delY' in x_delta)) {
        console.error('Invalid delta object provided:', x_delta);
        return; // Exit the function early if delta is invalid
    }

    // Update globalElements state
    x_setGlobalElements((prevState) => {
        // Create a new object to avoid mutating prevState
        const newState = { ...prevState };

        // Iterate over each element id in the list
        for (let elemId of x_idList) {
            // Check if the element is a connector function
            if (IsCnctrFunc(elemId)) {
                // Update points for connector function elements
                const newGlbElm = { ...newState[elemId] };
                const { pts } = newGlbElm.style;
                for (let i = 0; i < pts.length; i++) {
                    pts[i][0] += x_delta.delX;
                    pts[i][1] += x_delta.delY;
                }
                newGlbElm.style = { ...newGlbElm.style, pts };
                newState[elemId] = newGlbElm;
            } else {
                // Update left and top properties for other elements
                const newGlbElm = { ...newState[elemId] };
                const { left, top } = newGlbElm.style;
                newGlbElm.style = {
                    ...newGlbElm.style,
                    left: `${parseFloat(left) + x_delta.delX}px`,
                    top: `${parseFloat(top) + x_delta.delY}px`
                };
                newState[elemId] = newGlbElm;
            }
        }

        // Return the updated state
        return newState;
    });
};

export {x_wrnStyleFunc, getTextById, x_panelNdCanvasSizeFunc, x_globalElementsUpdater, x_globalLanesUpdater, x_globalElementsModifier, x_globalLanesModifier, x_globalElementsPointsReset, x_globalElementsIdsReset, x_globalElementsModifier2}