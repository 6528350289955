import './App.css';
import Header from './components/Header';
import SidePanel from './components/SidePanel';
import Main from './components/Main';
import { ContextWrapper } from './Master';

function App() {

  return (
      <ContextWrapper>
        <Header/>
        <SidePanel/>
        <Main/>
      </ContextWrapper>
  );
}

export default App;
