import './styles/SidePanelStyle.css';
import ListAComp from "./subcomponents/Sp_ListAComp";
import ListBCComp from './subcomponents/Sp_ListBCComp';
import { list_a, list_b, list_c } from './data/dataSidePanel';
import { useMyContextSp } from '../Master';

export default function SidePanel() {

    const {collapsePanel, toggleFunc} = useMyContextSp()

    const list_bc_mod = list_c.map((elem)=>{
        return (<ListBCComp key = {elem.id} txt = {list_b[elem.id%10].txt} data= {elem.data} />);
    });

  return (

    <div className="side-panel">    
        <div className="head-button">
            <span>Shapes</span>
            <span id='collapse-button' className = {collapsePanel?'anticlock':'clock'} onClick={toggleFunc}>
                <img src="/project_svg/app_svg/angle2.svg" alt="angle" width="10px" />
                <img src="/project_svg/app_svg/angle2.svg" alt="angle" width="10px" />
            </span>          
        </div>

        <div className="menu">
            <ListAComp {...list_a[0]}/>
            <ul id="list-c-display">
                {list_bc_mod}
            </ul>
            <ListAComp {...list_a[1]}/>
        </div>

    </div>
    
  );
}