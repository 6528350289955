import { IsCnctrFunc, IsLaneFunc, isChildLaneFunc, isParentLaneFunc, isSingleLaneFunc } from "./data/stdClsFnc";
import { findAdjSingleLane, findDeleteIds, findParentId, separateLaneIds } from "./subcomponents/Mn_CanvasCompUtil_func";

const removeObject = function (elem_id, x_globalElementIdMaps, x_globalElementTexts, x_globalElements, x_globalLanes, x_setGlobalElementIdMaps, x_setGlobalElementTexts, 
    x_setGlobalElements, x_setGlobalLanes, x_showDummy, x_setShowDummy, x_setSlctdElemId, x_slctdIdList, x_setSlctdIdList, x_showDummyList, x_setShowDummyList) {

        const removeFlowObject = function (cnctr_id) {
            let { ids } = { ...x_globalElements[cnctr_id].style };
            let index = 0;
        
            for ( let key of ids) {
                let idxs = index?'idfs':'idis'
                if (key) {
                    x_setGlobalElementIdMaps((prevState) => {
                        if (prevState && prevState[key]) {
                            let newIdmap = {...prevState[key]};
        
                            if (newIdmap[idxs].includes(cnctr_id)) { 
                                let newIdxsArr = newIdmap[idxs].filter(ids => ids !== cnctr_id);
                                newIdmap[idxs] = newIdxsArr;       
                                return {...prevState, [key] : newIdmap}
                            } 
                            else { return prevState }
                        }                
                    })
                }
                index += 1;
            }
        
            if (x_globalElements.hasOwnProperty(cnctr_id)) {
                x_setGlobalElements((prevState)=>{
                    if (prevState && prevState.hasOwnProperty(cnctr_id)) {
                        let updatedObject = { ...prevState };
                        delete updatedObject[cnctr_id];
                        return updatedObject
                    }
                    else { return prevState }
                })
            }
        
            if (x_globalElementTexts.hasOwnProperty(cnctr_id)) {
                x_setGlobalElementTexts((prevState)=>{
                    if (prevState && prevState.hasOwnProperty(cnctr_id)) {
                        let updatedObject = { ...prevState };
                        delete updatedObject[cnctr_id];
                        return updatedObject
                    }
                    else { return prevState }
                })
            }
        }
        
        const removeAnyObject = function (x_id) {
            if (IsCnctrFunc(x_id)) { removeFlowObject(x_id); }
            else {
                if (x_globalElementIdMaps && x_globalElementIdMaps.hasOwnProperty(x_id)) {
                    for (let ids in x_globalElementIdMaps[x_id] ) {
                        for (let cnctr_ids of x_globalElementIdMaps[x_id][ids]) {
                            removeFlowObject(cnctr_ids);
                            // console.log(cnctr_ids);
                        };
                    }
                }
            }

            if (x_globalElements.hasOwnProperty(x_id)) {
                x_setGlobalElements((prevState)=>{
                    if (prevState && prevState.hasOwnProperty(x_id)) {
                        let updatedObject = { ...prevState };
                        delete updatedObject[x_id];
                        return updatedObject
                    }
                    else { return prevState }
                })
            }

            if (x_globalElementTexts.hasOwnProperty(x_id)) {
                x_setGlobalElementTexts((prevState)=>{
                    if (prevState && prevState.hasOwnProperty(x_id)) {
                        let updatedObject = { ...prevState };
                        delete updatedObject[x_id];
                        return updatedObject
                    }
                    else { return prevState }
                })
            }

            if (x_globalElementIdMaps && x_globalElementIdMaps.hasOwnProperty(x_id)) {
                x_setGlobalElementIdMaps((prevState)=>{
                    if (prevState && prevState.hasOwnProperty(x_id)) {
                        let updatedObject = { ...prevState };
                        delete updatedObject[x_id];
                        return updatedObject
                    }
                    else { return prevState }
                })
            }
        }

        function removeLaneObject (x_id) {
            if (isSingleLaneFunc(x_id)) {
                let {laneId: targetLaneId, superId: parentId} = separateLaneIds(x_id);
                let {height: targetHeight, idList: targetIdList} = {...x_globalLanes[targetLaneId]};
                let parentLaneIds = [...x_globalLanes[parentId].laneIds];
                let len = parentLaneIds.length;
                let index = parentLaneIds.indexOf(targetLaneId);

                if (index!== -1 && len > 1) {
                    let adjLaneId = (index === 0) ? parentLaneIds[index + 1] : parentLaneIds[index - 1];
                    let adjSingleLaneId = findAdjSingleLane(adjLaneId, index === 0, x_globalLanes);
                    x_setGlobalLanes((prevState) => {
                        // Create a new object to avoid mutating prevState
                        const newState = { ...prevState };                        
                        let {height: prevHeight, idList: prevIdList} = {...prevState[adjSingleLaneId]};
                        parentLaneIds.splice(index, 1);
                        delete newState[targetLaneId];
                        newState[parentId] = {...newState[parentId], laneIds: parentLaneIds};
                        newState[adjSingleLaneId] = {...newState[adjSingleLaneId], height: prevHeight + targetHeight, idList: prevIdList.concat(targetIdList)};
                        // Return the updated state
                        console.log(prevState, newState);
                        return newState;
                    });
                } else if (len === 1) {                   
                    x_setGlobalLanes((prevState) => {
                        // Create a new object to avoid mutating prevState
                        const newState = { ...prevState };
                        let grandParentId = findParentId(parentId, prevState);
                        if (grandParentId) {
                            let grandParentLaneIds = [...prevState[grandParentId].laneIds];
                            let index_Parent = grandParentLaneIds.indexOf(parentId);
                            if (index !== -1) { grandParentLaneIds[index_Parent] = targetLaneId}
                            delete newState[parentId];
                            newState[grandParentId] = { ...newState[grandParentId], laneIds:grandParentLaneIds };
                        }
                        
                        return newState;
                    });                    
                }
            } else if (isChildLaneFunc(x_id)) {
                x_setGlobalLanes((prevState) => {
                    // Create a new object to avoid mutating prevState
                    const newState = { ...prevState };
                    let childLaneIds = [...prevState[x_id].laneIds];
                    let parentId = findParentId(x_id, prevState);         
                    let parentLaneIds = [...prevState[parentId].laneIds];
                    let index = parentLaneIds.indexOf(x_id);
                    if (index !== -1) { parentLaneIds.splice(index, 1, ...childLaneIds)}
                    delete newState[x_id];
                    newState[parentId] = { ...newState[parentId], laneIds:parentLaneIds };
                    // Return the updated state
                    return newState;
                });
            } else if(isParentLaneFunc(x_id)) {
                let delIds = findDeleteIds(x_id, x_globalLanes);
                x_setGlobalLanes((prevState) => {
                    // Create a new object to avoid mutating prevState
                    const newState = { ...prevState };
                    delIds.forEach(laneId => {
                        delete newState[laneId];
                    })
                    // Return the updated state
                    return newState;
                });
            }
        }

    if (elem_id && x_showDummy && IsLaneFunc(elem_id)) {
        removeLaneObject(elem_id);
        x_setShowDummy(false);
        x_setSlctdElemId("");
    } else if (elem_id && x_showDummy) {
        removeAnyObject(elem_id);
        x_setShowDummy(false);
        x_setSlctdElemId("");
    } else if (x_showDummyList && (x_slctdIdList.length > 0)) {
        for (let x_id of x_slctdIdList) { x_id && removeAnyObject(x_id) }
        x_setShowDummyList(false); 
        x_setSlctdIdList([]);
    }
}

export {removeObject}