import { useMemo } from "react";
import { baseLineStyle, calcAngleDegrees, calcDistance, crclHeadStyle, crclPointStyle, infoBoxStyle, rectHorStyle, rectVerStyle } from "./Mn_connector_func";

const ConnectorAnchors1 = function ({id, lineStyle, handleMouseEnter, handleMouseLeave, handleMouseDown, handleTouch}){

        let points = useMemo(() => [...lineStyle['pts']],[lineStyle]);
        let len = points.length;
        let angl2 = calcAngleDegrees(points[len-2],points[len-1]);
        let trnsfrm2 =`rotate(${angl2}deg)`;

        const crclPoints = points.slice(1,-1).map((point, index)=>{
            return(
                <div key={index} className="crclAnchor crclPt" id={'crclPt-' + id + '-' + (index + 1)} style={crclPointStyle(point)} ></div>
            );
        })

        const rectSlides = points.slice(1, len - 2).map((point, index) => {
            const nextPoint = points[index + 2];
            let isYsame;   

            // Check if the line segment is horizontal or vertical
            if (point[0] === nextPoint[0]) { isYsame = 0 } // Vertical segment
            else if (point[1] === nextPoint[1]) { isYsame = 1 } // Horizontal segment 
            else {return null}

            if (Math.abs(point[1-isYsame] - nextPoint[1 - isYsame]) < 40) {
                return null
            }
            let midVal = (point[1-isYsame] + nextPoint[1 - isYsame]) / 2 ;

            if (isYsame) {
                return (
                    <div key={index} className="rectAnchor rectHor" id={'rectAnchorH-' + id + '-' + (index + 1)} style={rectHorStyle(point, midVal)} ></div>
                )
            } else {
                return (
                    <div key={index} className="rectAnchor rectVer" id={'rectAnchorV-' + id + '-' + (index + 1)} style={rectVerStyle(point, midVal)} ></div>
                )
            }
        })

        const baseline = points.slice(0,-1).map((point, index)=>{
            const nextPoint = points[index + 1];
            let lengt = calcDistance(point, nextPoint)
            let angl = calcAngleDegrees(point,nextPoint);
            let trnsf =`rotate(${angl}deg)`;

            return(
                <div key={index} className="baselineAnchor baseLine" id={'bsLine-' + id + '-' + (index)} style={baseLineStyle(point, lengt, trnsf)} ></div>
            );
        })

        return (
            <div className="connector-parent" id={id} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseDown={handleMouseDown} >
                {baseline}
                {crclPoints}
                <div className="crclAnchor crclTail" id={'crclTail-' + id + '-0'} style={crclPointStyle(points[0])} ></div>
                <div className="crclAnchor crclHead" id={'crclHead-' + id + '-' + (len-1)} onMouseEnter={() => {handleTouch(infoBoxStyle(points[len-1]))}} style={crclHeadStyle(points[len-1],trnsfrm2)} ></div>
                {rectSlides} 
            </div>
    )}

export default ConnectorAnchors1;
