import React from 'react';
import svgContents from '../data/objectSvgs';

// Default SVG Component for fallback
const DefaultSvg = ({ width, height }) => (
  <>
    <rect width={width} height={height} fill="lightgray" />
    <text x="50%" y="50%" textAnchor="middle" dominantBaseline="central" fill="white" fontSize="10">
      SVG Not Found
    </text>
  </>
);

const SvgIcon = ({ itemid, style, isHover}) => {
  const { width, height, top, left } = style;
  const numericWidth = parseFloat(width);
  const numericHeight = parseFloat(height);
  const SvgComponent = ((Number(itemid.slice(0, 4) === 1700)) ? svgContents[1700] : svgContents[Number(itemid.slice(0, 3))]) || DefaultSvg;

  return (
    <div className="svg-box" style={{ width, height, top, left }}>
      <svg
        width={numericWidth}
        height={numericHeight}
        viewBox={`0 0 ${numericWidth} ${numericHeight}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <SvgComponent width={numericWidth} height={numericHeight} isHover={isHover}/>
      </svg>
    </div>
  );
};

export default SvgIcon;
