import React from "react";
import { useMyContextMn } from '../../Master';
import { findLaneHeight,  } from "./Mn_CanvasCompUtil_func";
import { isChildLaneFunc, isParentLaneFunc, isSingleLaneFunc, swimLanePropsA } from "../data/stdClsFnc";

function SLane ({x_id, slane}) {

  const {globalLanes} = useMyContextMn();

    let isParentLane = isParentLaneFunc(x_id);
    let {position, length, laneIds} = slane;
    let sLaneHeight = findLaneHeight(x_id, globalLanes);
    let laneOrdinate = position[1];
    let laneCount = laneIds.length - 1;
    

    const laneLines = laneIds.map((laneId, index) => {

      if (isSingleLaneFunc(laneId)) {
        laneOrdinate += globalLanes[laneId].height;
        return (index<laneCount) && <line key={index} x1={position[0] + 20} y1={laneOrdinate} x2={position[0] + length + 20} y2={laneOrdinate} stroke="red" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
      }

      else if (isChildLaneFunc(laneId)) {
        let childPosition =[position[0] + 20,laneOrdinate];
        let childLength = length - 20;
        laneOrdinate += findLaneHeight(laneId,globalLanes);
        let slane = new swimLanePropsA( globalLanes[laneId].title, childPosition, childLength, globalLanes[laneId].laneIds);
        return (
          <React.Fragment key={index}>
            <SLane x_id={laneId} slane={slane}/>
            {(index<laneCount) && <line x1={position[0] + 20} y1={laneOrdinate} x2={position[0] + length + 20} y2={laneOrdinate} stroke="red" strokeWidth="1" vectorEffect="non-scaling-stroke"/>}
          </React.Fragment>
          
        )
      }

      else {
        console.error(`invalid id : ${laneId}`);
        return null
      }

    }) ;
  

  return (
    <g>
        {isParentLane && <rect x={position[0]}  y={position[1]} height={sLaneHeight} width={20 + length} fill="none" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>}
        <line x1={position[0] + 20} y1={position[1]} x2={position[0] + 20} y2={position[1] + sLaneHeight} stroke="blue" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
        {laneLines}
    </g>
  )
}

export default SLane;
