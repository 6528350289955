class list_item{
  constructor(id, txt){
    this.id = id;
    this.txt = txt;
  }
}

const list_a = [
    {txt:"BPMN(Complete)", img:"angle2"},
    {txt:"Dictionary", img:"angle2"}
];

const list_b = [
  new list_item(10,"Activities"),
  new list_item(11,"Gateways"),
  new list_item(12,"Swimlanes"),
  new list_item(13,"Data Objects"),
  new list_item(14,"Artifacts"),
  new list_item(15,"Start Events"),
  new list_item(16,"End Events"),
  new list_item(17,"Catching Indermediate Events"),
  new list_item(18,"Throwing Indermediate Events"),
  new list_item(19,"Connecting Objects")
];

const list_c = [
    {id: 10, data:[
        new list_item (100,"Task"),
        new list_item (101,"Collapsed Subprocess"),
        new list_item (102,"Event Subprocess"),
        new list_item (103,"Collapsed Event-Subprocess"),
        new list_item (104,"Expanded Subprocess")
    ]},
    {id: 11, data:[
        new list_item (110,"Exclusive (XOR) Gateway"),
        new list_item (111,"Parallel Gateway"),
        new list_item (112,"Event-based Gateway"),
        new list_item (113,"Inclusive Gateway"),
        new list_item (114,"Complex Gateway")
    ]},
    {id: 12, data:[
        new list_item (120,"Pool / Lane"),
        new list_item (121,"Collapsed Pool"),
        new list_item (122,"Additional Participants"),
    ]},
    {id: 13, data:[
        new list_item (130,"Data Object"),
        new list_item (131,"Data Store"),
        new list_item (132,"Message"),
    ]},
    {id: 14, data:[
        new list_item (140,"IT System"),
        new list_item (141,"Group"),
        new list_item (142,"Text Annotation"),
    ]},
    {id: 15, data:[
        new list_item (150,"Start Event"),
        new list_item (151,"Start Message Event"),
        new list_item (152,"Start Timer Event"),
        new list_item (153,"Start Error Event"),
        new list_item (154,"Start Compensation Event"),
        new list_item (155,"Start Parallel Multiple Event"),
        new list_item (156,"Start Escalation Event"),
        new list_item (157,"Start Conditional Event"),
        new list_item (158,"Start Signal Event"),
        new list_item (159,"Start Multiple Event")
    ]},
    {id: 16, data:[
        new list_item (160,"End Event"),
        new list_item (161,"End Escalation Event"),
        new list_item (162,"End Message Event"),
        new list_item (163,"End Error Event"),
        new list_item (164,"Cancel End Event"),
        new list_item (165,"End Compensation Event"),
        new list_item (166,"End Signal Event"),
        new list_item (167,"End Multiple Event"),
        new list_item (168,"Terminate End Event"),
    ]},
    {id: 17, data:[
        new list_item (170,"Intermediate Parallel Multiple Event"),
        new list_item (171,"Intermediate Escalation Event"),
        new list_item (172,"Intermediate Message Event"),
        new list_item (173,"Intermediate Timer Event"),
        new list_item (174,"Intermediate Error Event"),
        new list_item (175,"Intermediate Cancel Event"),
        new list_item (176,"Intermediate Compensation Event"),
        new list_item (177,"Intermediate Conditional Event"),
        new list_item (178,"Intermediate Signal Event"),
        new list_item (179,"Intermediate Multiple Event"),
        new list_item (1700,"Intermediate Link Event")
    ]},
    {id: 18, data:[
        new list_item (180,"Intermediate Escalation Event"),
        new list_item (181,"Intermediate Event"),
        new list_item (182,"Intermediate Message Event"),
        new list_item (183,"Intermediate Compensation Event"),
        new list_item (184,"Intermediate Signal Event"),
        new list_item (185,"Intermediate Multiple Event"),
        new list_item (186,"Intermediate Link Event")
    ]},
    {id: 19, data:[
        new list_item (190,"Sequence Flow"),
        new list_item (191,"Association (Undirected)"),
        new list_item (192,"Association (Unidirectional)"),
        new list_item (193,"Message Flow"),
        new list_item (194,"Association (Bidirectional)"),
    ]}
];


export {list_a, list_b, list_c};