import { useMemo } from "react";
import { hdGenDummyFunc, calcAngleDegrees } from "./Mn_connector_func";


const ConnectorSvg1Dummy = function ({id, lineStyle}){

        let points = useMemo(() => [...lineStyle['pts']],[lineStyle]);

        let len = points.length;
        let [xf, yf] = points[len-1];

        // for arrow head
        let head = hdGenDummyFunc(0, points[len-1]);
        let angl2 = calcAngleDegrees(points[len-2],points[len-1]);
        let trnsfrm2 =`rotate(${angl2}, ${xf}, ${yf})`;

        // for circlepoints
        const crclPoints = points.map((point, index)=>{
            return(
                <circle key={index} r="3" cx={point[0]} cy={point[1]} fill="lightgreen" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/> 
            );
        })

        // for rectangular slides
        const rectSlides = points.slice(1, len - 2).map((point, index) => {
            const nextPoint = points[index + 2];
            let isYsame;   

            // Check if the line segment is horizontal or vertical
            if (point[0] === nextPoint[0]) { isYsame = 0 } // Vertical segment
            else if (point[1] === nextPoint[1]) { isYsame = 1 } // Horizontal segment 
            else {return null}

            if (Math.abs(point[1-isYsame] - nextPoint[1 - isYsame]) < 40) {
                return null
            }
            let midVal = (point[1-isYsame] + nextPoint[1 - isYsame]) / 2 ;

            if (isYsame) {
                return (
                    <rect key={index} x={midVal-15} y={point[1]-3} width="30" height="6" fill="lightyellow" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
                )
            } else {
                return (
                    <rect key={index} x={point[0]-3} y={midVal-15} width="6" height="30" fill="lightyellow" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
                )
            }
        })

        return (
        <g id = {id} className="dobjct-connector-copy">            
            {/* main line */}
            <polyline points={points} fill="none" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
            {/* arrow head*/}
            <polyline points={head} transform={trnsfrm2} fill="black" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
            {crclPoints}
            {rectSlides}
        </g>
    )}

export default ConnectorSvg1Dummy;
