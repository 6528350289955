import { useMemo } from "react";
import { hdGenFunc, TlGenFunc, calcAngleDegrees, hdGenDummyFunc, TlGenDummyFunc } from "./Mn_connector_func";


const ConnectorSvg2 = function ({id, lineStyle, isHover}){

        const cnctrType = id=>Number(id.slice(2,3));
        let typ = (cnctrType(id));
        // typ = { 0 : "Sequence Flow", 1 : "Association (Undirected)", 2 : "Association (Unidirectional)", 3 : "Message Flow", 4 : "Association (Bidirectional)" }

        let points = useMemo(() => [...lineStyle['pts']],[lineStyle]);
        let len = points.length;
        let [xi,yi] = points[0], [xf, yf] = points[len-1];

        let head = isHover ? hdGenDummyFunc(typ, points[len-1]) : hdGenFunc(typ, points[len-1]);
        let tail = isHover ? TlGenDummyFunc(points[0]) : TlGenFunc(points[0]);

        let angl1 = calcAngleDegrees(points[0],points[1]);
        let angl2 = calcAngleDegrees(points[len-2],points[len-1]);
        let trnsfrm1 =`rotate(${angl1}, ${xi}, ${yi})`;
        let trnsfrm2 =`rotate(${angl2}, ${xf}, ${yf})`;

        const crclPoints = points.map((point, index)=>{
            return(
                <circle key={index} r="3" cx={point[0]} cy={point[1]} fill="lightgreen" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/> 
            );
        })

        return (
        <g id = {id} className="dobjct-connector">            
            {/* main line */}
            <polyline className="checker" points={points} fill="none" stroke="black" strokeDasharray="3,4" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
            {/* arrow head and tail */}
            {typ!==1 && <polyline className="checker" points={head} transform={trnsfrm2} fill={typ===0?"black":(typ===3?"white":"none")} stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>}
            {typ===4 && <polyline className="checker" points={tail} transform={trnsfrm1} fill="none" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>}         
            {isHover && crclPoints}
        </g>
    )}

export default ConnectorSvg2;
