import { indxFromCnctnId } from "./Mn_connector_func";
import { idMapSetterFunc} from "./Mn_CanvasComp_func";
import { dataUnderCursor, dataUnderPoint, discretize5, extractDropInfo, rectify} from "./Mn_CanvasCompUtil_func";
import { simplifyArrayFunc } from "./Mn_SingleConnector_func";
import { reCreateArrowPoints, setNewPoint } from "./Mn_connectorUtil_func";

function dragResizeFunction10 (e, x_slctdElemId, x_setSlctdElemId, x_dummyElementStyle, x_setDummyElementStyle, x_setDrgFnsh, x_setWrnFlag, 
    x_setGlobalElementIdMaps, x_globalHoversModifier, x_setObjectAnchorPtStyle, x_setShowObjectAnchorPt, x_setIsDragging) {
    e.stopPropagation();
    e.preventDefault();
    let selectedElement = e.target;
    let xx_slctdElemId = (x_slctdElemId.slice(3) === 'newElement') ? x_slctdElemId.slice(3) : x_slctdElemId;
    let dummyInitParams;

    // making deep copy of dummyElementStyle
    const xx_dummyElementStyle = {
        ids: [...x_dummyElementStyle.ids],
        pts: x_dummyElementStyle.pts.map(pt => [...pt])
    };
    
    let len = xx_dummyElementStyle['pts'].length;
    let currentId = null, currentSuperId = null;
    // console.log(x_slctdElemId, selectedElement, xx_slctdElemId); 

    function dummySetterFunction(x_index,moveX,moveY,initParam){
        x_setDummyElementStyle((prevDummy)=>{ 
            let newDummy = {...prevDummy};
            newDummy.pts[x_index][0] = rectify(initParam['X']+moveX);
            newDummy.pts[x_index][1] = rectify(initParam['Y']+moveY);
            
            return newDummy
        })} 

    function dummySetterFunctionHor(x_index, moveX, moveY, initParam) {
        x_setDummyElementStyle((prevDummy) => {
            let newDummy = { ...prevDummy };
            newDummy.pts[x_index][1] = rectify(initParam['Y']+moveY);
            newDummy.pts[x_index+1][1] = rectify(initParam['Y']+moveY);
        
            return newDummy;
        });
        }

    function dummySetterFunctionVer(x_index, moveX, moveY, initParam) {
        x_setDummyElementStyle((prevDummy) => {
            let newDummy = { ...prevDummy };
            newDummy.pts[x_index][0] = rectify(initParam['X']+moveX);
            newDummy.pts[x_index+1][0] = rectify(initParam['X']+moveX);
        
            return newDummy;
        });
        }
 
    function dummySetterFunctionAll(x_index, moveX, moveY, initParams) {
        x_setDummyElementStyle((prevDummy) => {
          let newDummy = { ...prevDummy };
      
          // Update newDummy.pts using initial and final points
          for (let i = 0; i < newDummy.pts.length; i++) {
            newDummy.pts[i][0] = rectify(initParams[i][0]+moveX);
            newDummy.pts[i][1] = rectify(initParams[i][1]+moveY);
          }
      
          return newDummy;
        });
      }
        
      function uponMsMv(overlapId, borderPt) {
    
        // Check if overlapId exists and extract id and direction parts
        const parts = overlapId ? overlapId.split('-') : [];
        const [, id, dir] = parts;
        const superId = id && dir ? `${id}-${dir.slice(0, -1)}` : null;
    
        // Handle hover state change for currentId
        if (id !== currentId) {
            if (currentId) {
                x_globalHoversModifier(currentId, false); // Clear previous hover
            }
            currentId = id;
            if (currentId) {
                x_globalHoversModifier(currentId, true); // Set new hover
            }
        }
    
        // Handle superId state change
        if (superId !== currentSuperId) {
            currentSuperId = superId;
            if (currentSuperId) {
                const borderPtParts = borderPt.split('-');
                const style = { left: (parseFloat(borderPtParts[0]) - 4)+'px', top: (parseFloat(borderPtParts[1]) - 4)+'px' };
                x_setObjectAnchorPtStyle(style);
                x_setShowObjectAnchorPt(true);
            } else {
                x_setShowObjectAnchorPt(false);
            }
        }
    }


    function uponMsUp1(x_index, x_overlapId, x_borderPt) {
    
        const { dropId, dropDir, superId, dropPt } = extractDropInfo(x_overlapId, x_borderPt);    
    
        const isIndxHd = Number(Boolean(x_index));
        const idxs = isIndxHd ? 'idfs' : 'idis';
        const idToremove = xx_dummyElementStyle['ids'][isIndxHd]?.split('-')[0] ?? null;
        const idToAdd = dropId;
    
        let shouldUpdateIdMap = false; // Boolean flag to indicate if idMapSetterFunc should be called
    
        // Wrapping x_setDummyElementStyle in a promise to handle asynchronous updates
        new Promise((resolve) => {
            x_setDummyElementStyle((prevDummy) => {
    
                let ids = [...xx_dummyElementStyle.ids];  //let ids = [...prevDummy.ids]; changed to solve redundant update in which ids changed but points didnt
                let pts = prevDummy.pts.map(pt => [...pt]);
                const prevSuperId = ids[isIndxHd];
                const [prevId, prevDir] = prevSuperId?.split('-') ?? [null, null];
                const [otherId, otherDir] = ids[1 - isIndxHd]?.split('-') ?? [null, null];
    
                if (dropId !== null && (dropId === otherId || superId === prevSuperId)) {
                    // Reset point if dropId matches otherId or directions are the same
                    pts[x_index] = [dummyInitParams.X, dummyInitParams.Y];
                } else {
                    // Update ids and recalculate points if dropId does not match prevId or directions differ
    
                    const headPoint = (isIndxHd && dropPt) ? dropPt : [...pts[len - 1]];
                    const tailPoint = (!isIndxHd && dropPt) ? dropPt : [...pts[0]];
                    const isInSequence = tailPoint[0] <= headPoint[0];
    
                    const headDirection = isIndxHd
                        ? dropDir?.slice(0, 1) ?? (isInSequence ? 'D' : 'B')
                        : otherDir?.slice(0, 1) ?? (isInSequence ? 'D' : 'B');
                    const tailDirection = isIndxHd
                        ? otherDir?.slice(0, 1) ?? (isInSequence ? 'B' : 'D')
                        : dropDir?.slice(0, 1) ?? (isInSequence ? 'B' : 'D');
    
                    if ( dropId !== null && dropId === prevId && len > 2) {
                        pts = setNewPoint(isIndxHd, pts, [dummyInitParams.X, dummyInitParams.Y], prevDir.slice(0, 1), dropDir.slice(0, 1), dropPt);
                        pts = simplifyArrayFunc(pts);
                        ids[isIndxHd] = superId;
                        shouldUpdateIdMap = true;
                    } else if ((dropId !== prevId || (dropId !== null && dropId === prevId && len === 2))) {
                        pts = reCreateArrowPoints(tailPoint, headPoint, tailDirection, headDirection);
                        ids[isIndxHd] = superId;
                        shouldUpdateIdMap = true;
                    }
                }
    
                resolve(shouldUpdateIdMap);
                return { ids, pts };
            });
        }).then((shouldUpdateIdMap) => {
            // Call idMapSetterFunc if needed
            if (shouldUpdateIdMap) {
                idMapSetterFunc(idToremove, idToAdd, idxs, x_setGlobalElementIdMaps, x_slctdElemId);
            }
        });
    
        (!!currentId) && x_globalHoversModifier(currentId, false); // Clearing previous element's hover
        x_setShowObjectAnchorPt(false);
        
    }   
         

    function uponMsUp2(x_overlapIdi, x_overlapIdf, x_borderPti, x_borderPtf) { //hovering has not been activated in this case
    
        // Extract drop information from the overlap IDs
        const { dropId: dropIdi, dropDir: dropDirI, superId: superIdI, dropPt: dropPtI } = extractDropInfo(x_overlapIdi, x_borderPti);
        const { dropId: dropIdf, dropDir: dropDirF, superId: superIdF, dropPt: dropPtF } = extractDropInfo(x_overlapIdf, x_borderPtf);
    
        let updateCounter = 0;  // Counter to monitor repeated updates
        let isIdiChange = false;  // Boolean flag to indicate if idMapSetterFunc should be called
        let isIdfChange = false;
    
        new Promise((resolve) => {
            x_setDummyElementStyle((prevDummy) => {

                updateCounter++;
    
                //Prevent more than one update within a short window
                if (updateCounter > 1) {
                    console.warn("Dummy element style is being updated more than once unexpectedly, skipping redundant update.");
                }
    
                let ids = [...xx_dummyElementStyle.ids];  //let ids = [...prevDummy.ids]; changed to solve redundant update in which ids changed but points didnt
                let pts = prevDummy.pts.map(pt => [...pt]);
                const prevSuperIdI = ids[0];
                const prevSuperIdF = ids[1];
                const [prevIdI, ] = prevSuperIdI?.split('-') ?? [null, null];
                const [prevIdF, ] = prevSuperIdF?.split('-') ?? [null, null];
    
                isIdiChange = dropIdi !== prevIdI;
                isIdfChange = dropIdf !== prevIdF;
    
                if (isIdiChange || isIdfChange) {
                    const headPoint = dropPtF ?? [...pts[len - 1]];
                    const tailPoint = dropPtI ?? [...pts[0]];
                    const isInSequence = tailPoint[0] <= headPoint[0];
                    const headDirection = dropDirF?.slice(0, 1) ?? (isInSequence ? 'D' : 'B');
                    const tailDirection = dropDirI?.slice(0, 1) ?? (isInSequence ? 'B' : 'D');
    
                    pts = reCreateArrowPoints(tailPoint, headPoint, tailDirection, headDirection);
                    ids[0] = superIdI;
                    ids[1] = superIdF;
                } else {
                    if (dropIdi !== null && !isIdiChange) { ids[0] = superIdI; } 
                    if (dropIdf !== null && !isIdfChange) { ids[1] = superIdF; }
                }
    
                resolve({ isIdiChange, isIdfChange });
                return { ids: ids, pts: pts };
            });
        }).then(({ isIdiChange, isIdfChange }) => {

            if (xx_slctdElemId !== 'newElement') {

                if (isIdiChange) {
                    const idToremoveI = xx_dummyElementStyle['ids'][0]?.split('-')[0] ?? null;
                    const idToAddI = dropIdi;
                    idMapSetterFunc(idToremoveI, idToAddI, 'idis', x_setGlobalElementIdMaps, x_slctdElemId);
                }
        
                if (isIdfChange) {
                    const idToremoveF = xx_dummyElementStyle['ids'][1]?.split('-')[0] ?? null;
                    const idToAddF = dropIdf;
                    idMapSetterFunc(idToremoveF, idToAddF, 'idfs', x_setGlobalElementIdMaps, x_slctdElemId);
                }
            }
            
        });
    
        if (xx_slctdElemId === 'newElement') {
            x_setSlctdElemId('newElement');
            x_setDrgFnsh(prevState => prevState+1);
            x_setWrnFlag(false);
        }
    }

    function uponMsUp3() {
        x_setDummyElementStyle((prevDummy) => {
            let ids = [...xx_dummyElementStyle.ids];
            let pts = prevDummy.pts.map(pt => [...pt]);
            pts = simplifyArrayFunc(pts);
            return { ids, pts };
        });
    } 

    if (selectedElement.classList.contains('crclHeadD')) {funcDrag(e,len-1,dummySetterFunction,uponMsMv,uponMsUp1);}
    else if (selectedElement.classList.contains('crclTailD')) {funcDrag(e,0,dummySetterFunction,uponMsMv,uponMsUp1);}
    else if (selectedElement.classList.contains('rectHorD')) {
        const ptIndx = indxFromCnctnId(selectedElement.id);
        funcDrag(e,ptIndx,dummySetterFunctionHor,undefined,uponMsUp3);
    }
    else if (selectedElement.classList.contains('rectVerD')) {
        const ptIndx = indxFromCnctnId(selectedElement.id);
        funcDrag(e,ptIndx,dummySetterFunctionVer,undefined,uponMsUp3);
    }
    else if (selectedElement.classList.contains('crclPtD')) {
        const ptIndx = indxFromCnctnId(selectedElement.id);
        funcDrag(e,ptIndx,dummySetterFunction,undefined,uponMsUp3);
    }
    else if (selectedElement.classList.contains('baseLineD')) { 
        funcDrag(e,-1,dummySetterFunctionAll,undefined,uponMsUp2)
    }

    function funcDrag(e,index,setterFunc,x_uponMsMv,x_uponMsUp) {
        
        x_setIsDragging(true);
        let startX = e.clientX, startY = e.clientY ;
        let moveX = 0, moveY = 0;

        if (index === -1) { dummyInitParams = xx_dummyElementStyle.pts.map((point) => [...point])}
        else {dummyInitParams = {X: xx_dummyElementStyle.pts[index][0], Y: xx_dummyElementStyle.pts[index][1]};}
    
        window.addEventListener("mousemove", funcMouseMove);
        window.addEventListener("mouseup", funcMouseUp);
    
        function funcMouseMove(e){
            moveX = e.clientX - startX; moveX = discretize5(moveX);
            moveY = e.clientY - startY; moveY = discretize5(moveY);
    
            setterFunc(index,moveX,moveY,dummyInitParams);

            if (x_uponMsMv) {
                const { id: overlapId, data: borderPt } = dataUnderCursor(e, 'dobjctAnchorClass');
                x_uponMsMv(overlapId, borderPt);
            }
        }
    
        function funcMouseUp(e){        
            window.removeEventListener("mousemove", funcMouseMove);
            window.removeEventListener("mouseup", funcMouseUp); 

            x_setIsDragging(false);
            if (moveX === 0 && moveY === 0) { return; }          
            
            if (x_uponMsUp) { 
                if (index === 0 || index === len-1) {
                    const { id: overlapId, data: borderPt } = dataUnderCursor(e, 'dobjctAnchorClass');
                    x_uponMsUp(index, overlapId, borderPt);
                }
                else if (index === -1) {
                    let l = dummyInitParams.length;
                    let delX = e.clientX - startX, delY = e.clientY - startY;
                    let xi = dummyInitParams[0][0] + delX, yi = dummyInitParams[0][1] + delY;
                    let xf = dummyInitParams[l-1][0] + delX, yf = dummyInitParams[l-1][1] + delY;
                    const { id: overlapIdi, data: borderPti } = dataUnderPoint([xi, yi], 'dobjctAnchorClass');
                    const { id: overlapIdf, data: borderPtf } = dataUnderPoint([xf, yf], 'dobjctAnchorClass');
                    x_uponMsUp(overlapIdi, overlapIdf, borderPti, borderPtf);
                }
                else {x_uponMsUp()}
            }
  
        }
    }
    
}

export {dragResizeFunction10};