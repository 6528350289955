// Constants for default offsets
const DEL_Y = 40, DEL_X = 50;

// Generalized function to generate midpoints between two points
function generateMidPoints(startPt, endPt, isVertical, offset = 0) {
    const midValue = offset !== 0 ? offset : Math.round((isVertical ? (startPt[0] + endPt[0]) : (startPt[1] + endPt[1])) / 2);
    if (isVertical) {
        return [[midValue, startPt[1]], [midValue, endPt[1]]];
    } else {
        return [[startPt[0], midValue], [endPt[0], midValue]];
    }
}

// Vertical Line: creates a vertical line with optional offset
function createVerticalLine(startPt, endPt, offset = 0) {
    return [[...startPt], ...generateMidPoints(startPt, endPt, true, offset), [...endPt]];
}

// Horizontal Line: creates a horizontal line with optional offset
function createHorizontalLine(startPt, endPt, offset = 0) {
    return [[...startPt], ...generateMidPoints(startPt, endPt, false, offset), [...endPt]];
}

// First horizontal, then vertical line
function createLlineHorizontalFirst(startPt, endPt) {
    return [[...startPt], [endPt[0], startPt[1]], [...endPt]];
}

// First vertical, then horizontal line
function createLlineVerticalFirst(startPt, endPt) {
    return [[...startPt], [startPt[0], endPt[1]], [...endPt]];
}

// DIRECTION_TOP = A : DIRECTION_TOP , B : DIRECTION_RIGHT, C : DIRECTION_BOTTOM, D : DIRECTION_LEFT

// Generates an S-shape or a straight line based on direction
function generateSShape(startPt, endPt, dir) {
    return dir === 'B' ? createVerticalLine(startPt, endPt) : createHorizontalLine(startPt, endPt);
}

// Generates a U-shape based on direction
function generateUShape(startPt, endPt, dir) {
  let offset;
  switch (dir) {
      case 'B':
          offset = Math.max(startPt[0], endPt[0]) + DEL_X;
          return createVerticalLine(startPt, endPt, offset);
      case 'D':
          offset = Math.min(startPt[0], endPt[0]) - DEL_X;
          return createVerticalLine(startPt, endPt, offset);
      case 'A':
          offset = Math.min(startPt[1], endPt[1]) - DEL_Y;
          return createHorizontalLine(startPt, endPt, offset);
      case 'C':
          offset = Math.max(startPt[1], endPt[1]) + DEL_Y;
          return createHorizontalLine(startPt, endPt, offset);
      default: // If no direction matches
          console.error('no direction matches');
          return [startPt, endPt];
  }
}


// Generates a more complex S-shape
function generateComplexSShape(startPt, endPt, dir) {
    if (dir === 'D') {
        return [startPt, ...generateSShape([startPt[0] - DEL_X, startPt[1]], [endPt[0] + DEL_X, endPt[1]], 'A'), endPt];
    } else if (dir === 'A' || dir === 'C') {
        const sign = (dir === 'A') ? -1 : 1;
        return [startPt, ...generateSShape([startPt[0], startPt[1] + sign * DEL_Y], [endPt[0], endPt[1] - sign * DEL_Y], 'B'), endPt];
    } else {
        // If no direction matches
        console.error('no direction matches');
        return [startPt, endPt];
    }
}

// Generates P-shape based on direction
function generatePShape(startPt, endPt, dir) {
    switch (dir) {
        case 'B':
            return [startPt, ...generateUShape([startPt[0] + DEL_X, startPt[1]], [endPt[0] + DEL_X, endPt[1]], 'C'), endPt];
        case 'D':
            return [startPt, ...generateUShape([startPt[0] - DEL_X, startPt[1]], [endPt[0] - DEL_X, endPt[1]], 'C'), endPt];
        case 'A':
            return [startPt, ...generateUShape([startPt[0], startPt[1] - DEL_Y], [endPt[0], endPt[1] - DEL_Y], 'B'), endPt];
        case 'C':
            return [startPt, ...generateUShape([startPt[0], startPt[1] + DEL_Y], [endPt[0], endPt[1] + DEL_Y], 'B'), endPt];
        default:  // If no direction matches
            console.error('no direction matches');
            return [startPt, endPt];  // If no direction matches
    }
}

// Generates a C-shape based on direction
function generateCShape(startPt, endPt, dir) {
    if (dir === 'D') {
        return [startPt, ...generateUShape([startPt[0] - DEL_X, startPt[1]], [endPt[0] + DEL_X, endPt[1]], 'C'), endPt];
    } else if (dir === 'A' || dir === 'C') {
        const sign = (dir === 'A') ? -1 : 1;
        return [startPt, ...generateUShape([startPt[0], startPt[1] + sign * DEL_Y], [endPt[0], endPt[1] - sign * DEL_Y], 'B'), endPt];
    } else {
        // If no direction matches
        console.error('no direction matches');
        return [startPt, endPt];
    }
}

// Generates an L-shape based on direction
function generateLShape(startPt, endPt, dir) {
    return dir === 'B' ? createLlineHorizontalFirst(startPt, endPt) : createLlineVerticalFirst(startPt, endPt);
}

// Generates an N-shape based on two directions
function generateNShape(startPt, endPt, startDir, endDir) {
    const dir = startDir + endDir;

    switch (dir) {
        case 'BA':
            return [...generateSShape(startPt, [endPt[0], endPt[1] - DEL_Y], 'B'), endPt];
        case 'BC':
            return [...generateSShape(startPt, [endPt[0], endPt[1] + DEL_Y], 'B'), endPt];
        case 'AB':
            return [...generateSShape(startPt, [endPt[0] + DEL_X, endPt[1]], 'A'), endPt];
        case 'AD':
            return [startPt, ...generateSShape([startPt[0], startPt[1] - DEL_Y], endPt, 'B')];
        case 'DC':
            return [startPt, ...generateSShape([startPt[0] - DEL_X, startPt[1]], endPt, 'A')];
        case 'DA':
            return [startPt, ...generateSShape([startPt[0] - DEL_X, startPt[1]], endPt, 'C')];
        case 'CD':
            return [startPt, ...generateSShape([startPt[0], startPt[1] + DEL_Y], endPt, 'B')];
        case 'CB':
            return [...generateSShape(startPt, [endPt[0] + DEL_X, endPt[1]], 'C'), endPt];
        default:  // If no direction matches
            console.error('no direction matches');
            return [startPt, endPt];
    }
}

// Generates an O-shape based on two directions
function generateOShape(startPt, endPt, startDir, endDir) {
    const dir = startDir + endDir;

    switch (dir) {
        case 'DA':
            return [startPt, ...createLlineVerticalFirst([startPt[0] - DEL_X, startPt[1]], [endPt[0], endPt[1] - DEL_Y]), endPt];
        case 'DC':
            return [startPt, ...createLlineVerticalFirst([startPt[0] - DEL_X, startPt[1]], [endPt[0], endPt[1] + DEL_Y]), endPt];
        case 'CB':
            return [startPt, ...createLlineHorizontalFirst([startPt[0], startPt[1] + DEL_Y], [endPt[0] + DEL_X, endPt[1]]), endPt];
        case 'AB':
            return [startPt, ...createLlineHorizontalFirst([startPt[0], startPt[1] - DEL_Y], [endPt[0] + DEL_X, endPt[1]]), endPt];
        default:  // If no direction matches
            console.error('no direction matches');
            return [startPt, endPt];
    }
}

// Generates X-shape based on vertical A and C directions
function generateXShape(startPt, endPt, startDir, endDir, reg) {
    if (startDir === 'A') {
        return reg === 2 ? generateSShape(startPt, endPt, 'A') : generateCShape(startPt, endPt, 'A');
    } else if (startDir === 'C') {
        return reg === 3 ? generateSShape(startPt, endPt, 'C') : generateCShape(startPt, endPt, 'C');
    }
}

const algoIndexToFunctionMap = {
    1: generateSShape,
    2: generateUShape,
    3: generateComplexSShape, 
    40: generatePShape,
    41: generateCShape,
    5: generateLShape,
    6: generateNShape,
    7: generateOShape
};

function invokeShapeGeneration(algoIndex, startPt, endPt, startDir, endDir) {
    if (algoIndexToFunctionMap[algoIndex]) {
        return algoIndexToFunctionMap[algoIndex](startPt, endPt, startDir, endDir);
    } else {
        console.error(`Algorithm with index ${algoIndex} does not exist in algoIndexToFunctionMap`);
        return [startPt, endPt];
    }
}

// Define the grid values and their respective regions
// 0: hor, 12: up-ver, 13: dn-ver, 2: up, 3: dn
const regions = [
    [12, 12, 2],
    [12, 2, 0],
    [12, 0, 0],
    [13, 0, 0],
    [13, 3, 0],
    [13, 13, 3]
];

const MAX_COLUMN = 2;
const MAX_ROW = 5;
const ROW_OFFSET = 3;

// Define function to determine the region for a given point (x, y) for updated grid
function getRegion(x, y, deltaX = 2 * DEL_X, deltaY = 2 * DEL_Y) {
  const col = Math.min(MAX_COLUMN, Math.floor(x / deltaX));
  const row = Math.min(MAX_ROW, Math.max(0, ROW_OFFSET + Math.floor(y / deltaY)));
  return regions[row][col];
}

// Arrow shape master matrix
// 0: A, 1: B, 2: C, 3: D e.g., arrowMatrix[1][0] -> arrowMatrix B-A shape for different regions
const arrowMatrix = [
    [[2, 40, 2, 2], [7, 8, 6, 7], [3, 10, 1, 3], [6, 9, 5, 6]],
    [[6, 9, 6, 5], [40, 2, 2, 2], [6, 9, 5, 6], [1, 9, 1, 1]],
    [[3, 10, 3, 1], [7, 8, 7, 6], [2, 40, 2, 2], [6, 9, 6, 5]],
    [[7, 8, 7, 6], [41, 3, 3, 3], [7, 8, 6, 7], [40, 2, 2, 2]]
];

const directionIndexMap = {
  'A': 0,
  'B': 1,
  'C': 2,
  'D': 3
};

function isSimpleConnection(startPt, endPt, startDir, endDir) {
    return (
        (startPt[1] === endPt[1] && startDir === 'B' && endDir === 'D') ||
        (startPt[0] === endPt[0] && startPt[1] > endPt[1] && startDir === 'C' && endDir === 'A') ||
        (startPt[0] === endPt[0] && startPt[1] < endPt[1] && startDir === 'A' && endDir === 'C')
    );
}

function generateArrowPoints(startPt, endPt, startDir, endDir, region, isInSeq) {
    let algo_index, points;
    const startIndex = directionIndexMap[startDir];
    const endIndex = directionIndexMap[endDir];

    if ([0, 2, 3].includes(region)) {
        algo_index = arrowMatrix[startIndex][endIndex][region];
        points = invokeShapeGeneration(algo_index, startPt, endPt, startDir, endDir);
    } else if ([12, 13].includes(region)) {
        points = handleComplexRegion(startPt, endPt, startDir, endDir, region);
    }

    return isInSeq ? points : [...points].reverse();
}

function handleComplexRegion(startPt, endPt, startDir, endDir, region) {
    const startIndex = directionIndexMap[startDir];
    const endIndex = directionIndexMap[endDir];
    let algo_index = arrowMatrix[startIndex][endIndex][Math.floor(region / 10)];
    region %= 10;

    switch (algo_index) {
        case 8:
            algo_index = arrowMatrix[startIndex][endIndex][region];
            return invokeShapeGeneration(algo_index, startPt, endPt, startDir, endDir);
        
        case 9:
            region = 5 - region;
            algo_index = arrowMatrix[endIndex][startIndex][region];
            return invokeShapeGeneration(algo_index, endPt, startPt, endDir, startDir).reverse();
        
        case 10:
            return generateXShape(startPt, endPt, startDir, endDir, region);
        
        default:
            return invokeShapeGeneration(algo_index, startPt, endPt, startDir, endDir);
    }
}

function reCreateArrowPoints(TlPt, HdPt, TlDir, HdDir) {
    console.log('recreate arrow points ran');

    let isInSeq = TlPt[0] <= HdPt[0];
    const x_startPt = isInSeq ? TlPt : HdPt;
    const x_endPt = isInSeq ? HdPt : TlPt;
    const x_startDir = isInSeq ? TlDir : HdDir;
    const x_endDir = isInSeq ? HdDir : TlDir;

    if (isSimpleConnection(x_startPt, x_endPt, x_startDir, x_endDir)) {
        return isInSeq ? [x_startPt, x_endPt] : [x_endPt, x_startPt];
    }

    const diff_x = x_endPt[0] - x_startPt[0];
    const diff_y = x_endPt[1] - x_startPt[1];
    let region = getRegion(diff_x, diff_y);
    return generateArrowPoints(x_startPt, x_endPt, x_startDir, x_endDir, region, isInSeq);
}


function getDirPairType(direction1, direction2) {
    const directionsList = ["A", "B", "C", "D"]

    // Ensure direction1 and direction2 are in the directionsList
    if (!directionsList.includes(direction1) || !directionsList.includes(direction2)) {
        throw new Error("Both direction1 and direction2 must be in the directionsList.");
    }

    // Find the indices of the given directionsList
    const index1 = directionsList.indexOf(direction1);
    const index2 = directionsList.indexOf(direction2);

    // Calculate the difference in their indices
    const diff = Math.abs(index1 - index2);

    // Determine if they are same, adjacent or opposite
    if (diff === 0) {
        return "Same";
    } else if (diff === 1 || diff === 3) {
        return "Adjacent";
    } else if (diff === 2) {
        return "Opposite";
    } else {
        return null;
    }
}

function getNextPoint(isHd, currDir, currPt) {
    let nextDir, nextPt;

    switch (currDir) {
        case 'A':
            nextDir = isHd ? 'D' : 'B';
            nextPt = [currPt[0], currPt[1] - DEL_Y];
            break;
        case 'B':
            nextDir = isHd ? 'C' : 'A';
            nextPt = [currPt[0] + DEL_X, currPt[1]];
            break;
        case 'C':
            nextDir = isHd ? 'D' : 'B';
            nextPt = [currPt[0], currPt[1] + DEL_Y];
            break;
        case 'D':
            nextDir = isHd ? 'C' : 'A';
            nextPt = [currPt[0] - DEL_X, currPt[1]];
            break;
        default:
            throw new Error("Invalid current direction");
    }

    return { nextDir, nextPt };
}

function getNewPointsAdjDir(currDir, currPt, prevPt1) {
    switch (currDir) {
        case 'A':
            return [currPt, [currPt[0], currPt[1] - DEL_Y], [prevPt1[0], currPt[1] - DEL_Y]];
        case 'B':
            return [currPt, [currPt[0] + DEL_X, currPt[1]], [currPt[0] + DEL_X, prevPt1[1]]];
        case 'C':
            return [currPt, [currPt[0], currPt[1] + DEL_Y], [prevPt1[0], currPt[1] + DEL_Y]];
        case 'D':
            return [currPt, [currPt[0] - DEL_X, currPt[1]], [currPt[0] - DEL_X, prevPt1[1]]];
        default:
            throw new Error("Invalid current direction");
    }
}

function getNewPointsSameDir(currDir, currPt, prevPt1, prevPt0) {
    let diff;
    switch (currDir) {
        case 'A':
        case 'C':
            diff = currPt[0] - prevPt0[0];
            return [currPt, [prevPt1[0] + diff, prevPt1[1]]];
        case 'B':
        case 'D':
            diff = currPt[1] - prevPt0[1];
            return [currPt, [prevPt1[0], prevPt1[1] + diff]];
        default:
            throw new Error("Invalid current direction");
    }
}

function setNewPoint(isHd, pts, prevPt0, prevDir, currDir, currPt) {
    console.log('setNewPoint function ran')
    
    let newPoints, finalPoints;
    finalPoints = pts.map(pt => [...pt]);
    const len = finalPoints.length;

    const prevPt1 = isHd ? finalPoints[len - 2] : finalPoints[1]; // second (-last ) / second

    const dirPairType = getDirPairType(prevDir, currDir); // Store result to avoid multiple calls

    switch (dirPairType) {
        case "Same":
            newPoints = getNewPointsSameDir(currDir, currPt, prevPt1, prevPt0);
            break;
        case "Adjacent":
            newPoints = getNewPointsAdjDir(currDir, currPt, prevPt1);
            break;
        case "Opposite":
            const { nextDir, nextPt } = getNextPoint(isHd, currDir, currPt);
            newPoints = getNewPointsAdjDir(nextDir, nextPt, prevPt1);
            newPoints.splice(0, 0, currPt); // Add current point directly
            break;
        default:
            throw new Error("Invalid direction pair");
    }

    if (isHd) {
        finalPoints.splice(-2, 2, ...newPoints.reverse()); // Spread newPoints to match array structure
    } else {
        finalPoints.splice(0, 2, ...newPoints); // Spread newPoints to match array structure
    }

    return finalPoints;
}

export {reCreateArrowPoints, setNewPoint}