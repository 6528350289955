import React from 'react';

const InfoBoxIcon = () => (
    <svg className="icon1" width="20" height="20" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
        <polygon points="35,25 25,25 25,35 35,25 35,5 5,5 5,35 25,35" fill="white" stroke="gray" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
        <line x1="10" y1="15" x2="30" y2="15" stroke="gray" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
        <line x1="10" y1="23" x2="20" y2="23" stroke="gray" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
    </svg>
);

export default InfoBoxIcon;
