import React from 'react';
import generateChildBoxesById from './Mn_ObjectAnchors_func';


const ObjectAnchors = ({ itemid, style }) => {
  const { width, height, top, left } = style;
  const l = parseFloat(left);
  const t = parseFloat(top);
  const w = parseFloat(width);
  const h = parseFloat(height);
  const id = Number(itemid.slice(0, 3));

  // Assign CSS styles to each box dynamically
  const boxStyles = { top: top, left: left, width: width, height: height, };

  const childBoxStyle = (x, y, w, h) => ({ left: x, top: y, width: w, height: h, });

  const childBoxes = generateChildBoxesById(id, w, h );

  return (
    <div className='dobjctAnchorContainer' style={boxStyles}>
      {childBoxes.map((box, index) => (
        <div key={index} id={'objectId-'+ itemid + '-' + box.id} data-borderpt={`${box.borderReference[0] + l}-${box.borderReference[1] + t}`} className='dobjctAnchorClass' style={childBoxStyle(box.x, box.y, box.w, box.h)}></div>
      ))}
    </div>
  );
};

export default ObjectAnchors;
  