import { isInvalidFunc, discretize10, rectify, rectifySize } from "./Mn_CanvasCompUtil_func";

function dragResizeFunction2 (e, x_slctdElemId, x_setSlctdElemId, x_dummyElementStyle, x_setDummyElementStyle, x_setDrgFnsh, x_setClsInvld, x_setWrnFlag, x_setObjectMoveDir, x_setIsDragging) {
    e.stopPropagation();
    e.preventDefault();
    let selectedElement = e.target;
    let xx_slctdElemId = (x_slctdElemId.slice(3) === 'newElement') ? x_slctdElemId.slice(3) : x_slctdElemId ;
    // console.log(selectedElement.nextSibling, IsLaneFunc(x_slctdElemId)); 
    
    let zero = 0;

    function dummySetterFunction(paramX,paramY,moveX,moveY,dumParam){
        
        function setFunc (calbk) {
            x_setDummyElementStyle((preVdummy)=>{ return {...preVdummy, 
                [paramX]: calbk(parseFloat(dumParam[paramX])+moveX)+'px',
                [paramY]: calbk(parseFloat(dumParam[paramY])+moveY)+'px'
            }})
        }

        if (paramX === 'left') { setFunc(rectify)}
        else if (paramX === 'width') { setFunc(rectifySize)}
        
    }  
        
    function uponMsMv(x_isInvalidVar) {
        x_setWrnFlag(!x_isInvalidVar);
        x_setClsInvld(x_isInvalidVar);
    }

    function uponMsUp(x_varX,x_varY,x_moveX,x_moveY,x_dummyInitParams,x_isInvalidVar) {
        if(x_isInvalidVar) {
            if (xx_slctdElemId === 'newElement') {x_setSlctdElemId('newElement0');}
            else {dummySetterFunction(x_varX,x_varY,zero,zero,x_dummyInitParams);}
        }
        else {
            if (xx_slctdElemId === 'newElement') {x_setSlctdElemId('newElement');}
        }
        x_setObjectMoveDir({delX : x_moveX, delY : x_moveY});
        x_setClsInvld(false);
        x_setDrgFnsh(prevState => prevState+1);
        x_setWrnFlag(false);
    }
    
    if (selectedElement.classList.contains('dobjct-copy')) {funcDrag(e,'left','top',dummySetterFunction,uponMsMv,uponMsUp);}
    else if (selectedElement.classList.contains('drsz')) {funcDrag(e,'width','height',dummySetterFunction,uponMsMv,uponMsUp);}

    function funcDrag(e,varX,varY,setterFunc,x_uponMsMv,x_uponMsUp) {
        // console.log("dragResizeFunction start");
        x_setIsDragging(true);
    
        let isInvalidVar;
        let startX = e.clientX ;
        let startY = e.clientY ;
        let dummyInitParams = {[varX]: x_dummyElementStyle[varX], [varY]: x_dummyElementStyle[varY]};

        let moveX = 0, moveY = 0;
    
        if (varX === 'width') { selectedElement = selectedElement.parentNode}
    
        window.addEventListener("mousemove", funcMouseMove);
        window.addEventListener("mouseup", funcMouseUp);
    
        function funcMouseMove(e){
            moveX = e.clientX - startX; moveX = discretize10(moveX);
            moveY = e.clientY - startY; moveY = discretize10(moveY);
    
            setterFunc(varX,varY,moveX,moveY,dummyInitParams);
            
            isInvalidVar = isInvalidFunc(selectedElement, xx_slctdElemId, "dobjct");
            x_uponMsMv(isInvalidVar);         
        }
    
        function funcMouseUp(){        
            window.removeEventListener("mousemove", funcMouseMove);
            window.removeEventListener("mouseup", funcMouseUp);  

            x_setIsDragging(false);
            if (moveX === 0 && moveY === 0) { return; }            
            
            x_uponMsUp(varX,varY,moveX,moveY,dummyInitParams,isInvalidVar);           
            // console.log("dragResizeFunction stop");           
        }
    }
    
}

export {dragResizeFunction2};