import { discretize10 } from "./Mn_CanvasCompUtil_func";

function simplifyArrayFunc (arr) {

    let simplifiedArray = [];

    for (let j = 0; j < 2; j++) {
        if (j === 1) {
            arr = [...simplifiedArray];
        }
        let l = arr.length;
        if (l < 3) { return arr}
        simplifiedArray = [arr[0]];
        let prevSame = (arr[1][j] >= arr[0][j] - 6) && (arr[1][j] <= arr[0][j] + 6);
        
        for (let i = 2; i < l; i++) {
            let currSame = (arr[i][j] >= arr[i-1][j] - 6) && (arr[i][j] <= arr[i-1][j] + 6);
            if (!(prevSame && currSame)) {
                simplifiedArray.push(arr[i-1])
            }
            if (prevSame !== currSame) {
                prevSame = currSame
            }
        }
        simplifiedArray.push(arr[l-1]);
    }
    return simplifiedArray
}

// needds rigourous testing... held for the time being ..... behaviour not adequate
function simplifyArrayFurtherFunc (list) {

    let arr = simplifyArrayFunc(list);
    let l = arr.length;
    if (l < 3) { return arr}
    let prevSlope = discretize10(calcAngleDegrees(arr[0], arr[1]));
    let simplifiedArray = [[arr[0]]];

    for (let i = 2; i < l; i++) {
        let currSlope = discretize10(calcAngleDegrees(arr[i-1], arr[i]));
        if (prevSlope !== currSlope) {           
            simplifiedArray.push(arr[i-1])
        }
        prevSlope = currSlope;
    }
    simplifiedArray.push(arr[l-1]);

    return simplifiedArray
}

function hdGenFunc (type, [x,y]) {return [[x - 12,y - 4],[x,y], [x - 12,y + 4], type===3 && [x - 12,y - 4]]}
function hdGenDummyFunc (type, [x,y]) {return [[x - 15,y - 4],[x-3,y], [x - 15,y + 4], type===3 && [x - 15,y - 4]]}

function TlGenFunc ([x,y]) {return [[x + 12,y - 4],[x,y], [x + 12,y + 4]]}
function TlGenDummyFunc ([x,y]) {return [[x + 15,y - 4],[x-3,y], [x + 15,y + 4]]}

function calcAngleDegrees([x1,y1],[x2,y2]){
    let delX = x2 - x1, delY = y2 - y1 ;
    let angle = Math.atan2(delY, delX) * 180 / Math.PI ;
    angle = Math.round(angle);
    return angle
}

function updatePoints(xx_id, ids, pts) {

    let isSeq = (xx_id.slice(2,3) === '0');
    let len = pts.length;
    let [idi, idf] = ids;
    let recti, rectf;
    // idi = idUnderPoint(pts[0], 'dobjct');
    recti = idi && document.getElementById(idi).getBoundingClientRect();
    // idf = idUnderPoint(pts[len-1], 'dobjct');
    rectf = idf && document.getElementById(idf).getBoundingClientRect();

    if (len < 20) {
        let [x0,y0] = pts[0], [x1,y1] = pts[len-1],xi, yi, delXi, delYi, xf, yf, delXf, delYf, xn0, yn0, xn1, yn1;
 
        xi = (idi)?((recti.left + recti.right)/2 - 250):x0;
        xf = (idf)?((rectf.left + rectf.right)/2 - 250):x1;
        delXi = (idi)?(recti.width/2 - 5):0;
        delXf = (idf)?(rectf.width/2 - 5):0;
        yi = (idi)?((recti.top + recti.bottom)/2 - 84):y0;
        yf = (idf)?((rectf.top + rectf.bottom)/2 -84):y1;
        delYi = (idi)?(recti.height/2 - 5):0;
        delYf = (idf)?(rectf.height/2 - 5):0;
        // console.log(xi,yi,delXi,delYi);


        // case 1
        if (yi === yf) {
            yn0 = yn1 = yi;
            xn0 = xi + delXi * ((xf>xi)?1:-1);
            xn1 = xf + delXf * ((xf<xi)?1:-1);
            return [[xn0, yn0], [xn1, yn1]]
        }
        // case 2
        if (xi === xf) {
            xn0 = xn1 = xi;
            yn0 = yi + delYi * ((yf>yi)?1:-1);
            yn1 = yf + delYf * ((yf<yi)?1:-1);
            return [[xn0, yn0], [xn1, yn1]]
        }

        let m ;
        if (x0 === x1){ m = 4;}
        else {
            m = (y1-y0)/(x1-x0);
            m = Math.abs(m);
        }

        let xc = (xi+xf)/2, yc = (yi+yf)/2

        // case3
        if (m < 0.5) {
            yn0 = yi; yn1 = yf;
            xn0 = xi + delXi * ((xf>xi)?1:-1);
            xn1 = xf + delXf * ((xf<xi)?1:-1);
            if (isSeq) { return [[xn0, yn0], [xc,yn0], [xc,yn1], [xn1, yn1]] }
            else {return [[xn0, yn0], [xn1, yn1]]}
        }
        // case4
        if (m >= 0.5 && m <2) {
            xn0 = xi; yn1 = yf;
            yn0 = yi + delYi * ((yf>yi)?1:-1);
            xn1 = xf + delXf * ((xf<xi)?1:-1);
            if (isSeq) {return [[xn0, yn0], [xn0,yn1], [xn1, yn1]]}
            else {return [[xn0, yn0], [xn1, yn1]]}
        }
        // case5
        if (m >= 2) {
            xn0 =xi; xn1 = xf;
            yn0 = yi + delYi * ((yf>yi)?1:-1);
            yn1 = yf + delYf * ((yf<yi)?1:-1);
            if (isSeq) {return [[xn0, yn0], [xn0, yc], [xn1, yc], [xn1, yn1]]}
            else {return [[xn0, yn0], [xn1, yn1]]}
        }
    }

    return pts
}

function ptIndxInArrFunc(pt, arr) {
    let len = arr.length;
    if (len === 2) {return 1}
    for (let i = 1; i < len; i++) {
        let delXf = pt[0] - arr[i][0];
        let delXi = pt[0] - arr[i-1][0];
        let delYf = pt[1] - arr[i][1];
        let delYi = pt[1] - arr[i-1][1];
        if (
            (delXf === 0 && delXi === 0 && (delYf * delYi) < 0) ||
            ((delXf * delXi) < 0 && delYf === 0 && delYi === 0) ||
            ((delXf * delXi) < 0 && (delYf * delYi) < 0)
        ) {return i}
    }
    return null
}

export {simplifyArrayFunc, simplifyArrayFurtherFunc, hdGenFunc, hdGenDummyFunc, TlGenFunc, TlGenDummyFunc, calcAngleDegrees, updatePoints, ptIndxInArrFunc}