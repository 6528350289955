function hdGenFunc (type, [x,y]) {return [[x - 15,y - 3.7],[x,y], [x - 15,y + 3.7], type===3 && [x - 15,y - 3.7]]}
function hdGenDummyFunc (type, [x,y]) {return [[x - 15,y - 4],[x-3,y], [x - 15,y + 4], type===3 && [x - 15,y - 4]]}

function TlGenFunc ([x,y]) {return [[x + 15,y - 3.7],[x,y], [x + 15,y + 3.7]]}
function TlGenDummyFunc ([x,y]) {return [[x + 15,y - 4],[x+3,y], [x + 15,y + 4]]}

function calcAngleDegrees([x1,y1],[x2,y2]){
    let delX = x2 - x1, delY = y2 - y1 ;
    let angle = Math.atan2(delY, delX) * 180 / Math.PI ;
    angle = parseFloat(angle.toFixed(2));
    return angle
}

function calcDistance([x1,y1],[x2,y2]){
    let delX = x2 - x1, delY = y2 - y1 ;
    let length = Math.sqrt(delX * delX + delY * delY);
    length = Math.round(length);
    return length
}

function crclPointStyle (point) {return { left: (point[0] - 10) + 'px', top: (point[1] - 10) + 'px' }}
function crclHeadStyle (point, trnsfrm) {return { left: (point[0] - 20) + 'px', top: (point[1] - 10) + 'px', transform: trnsfrm, transformOrigin: '20px 10px'}}
function infoBoxStyle (point) {return { left: (point[0] + 10) + 'px', top: (point[1] + 10) + 'px' }}
function rectHorStyle (point, midVal) {return { left: (midVal - 20) + 'px', top: (point[1] - 5) + 'px', width: '40px', height: '10px' }}
function rectVerStyle (point, midVal) {return { left: (point[0] - 5) + 'px', top: (midVal - 20) + 'px', width: '10px', height: '40px' }}

function baseLineStyle (point, len, trnsfrm) {return { left: (point[0] - 5) + 'px', top: (point[1] - 5) + 'px', width: len + 'px', transform: trnsfrm, transformOrigin: '5px 5px'}}

function ptIndxInArrFunc(pt, arr) {
    let len = arr.length;
    if (len === 2) {return 1}
    for (let i = 1; i < len; i++) {
        let delXf = pt[0] - arr[i][0];
        let delXi = pt[0] - arr[i-1][0];
        let delYf = pt[1] - arr[i][1];
        let delYi = pt[1] - arr[i-1][1];
        if (
            (delXf === 0 && delXi === 0 && (delYf * delYi) < 0) ||
            ((delXf * delXi) < 0 && delYf === 0 && delYi === 0) ||
            ((delXf * delXi) < 0 && (delYf * delYi) < 0)
        ) {return i}
    }
    return null
}

function indxFromCnctnId(elementId) {
    // Split the id by '-'
    const parts = elementId.split('-');
  
    // Get the last part and convert it to an integer
    const index = parseInt(parts[parts.length - 1], 10);
  
    return index;
  }

export {hdGenFunc, hdGenDummyFunc, TlGenFunc, TlGenDummyFunc, calcAngleDegrees, calcDistance, crclPointStyle, crclHeadStyle, infoBoxStyle, rectHorStyle, rectVerStyle, baseLineStyle, 
    ptIndxInArrFunc, indxFromCnctnId}