import { isInvalidFunc, discretize10, rectify, idUnderCursor, addHighlightClass, addVanishClass } from "./Mn_CanvasCompUtil_func";

function dragResizeFunction3 (e, x_slctdElemId, x_setSlctdElemId, x_dummyElementStyle, x_setDummyElementStyle, x_setDrgFnsh, x_setClsInvld, x_setWrnFlag) {
    e.stopPropagation();
    e.preventDefault();

    let selectedElement = e.target;
    let xx_slctdElemId, isNew;

    if (x_slctdElemId.slice(3) === 'newElement') {
        isNew = true;
        xx_slctdElemId = x_slctdElemId.slice(3);
    } else {
        isNew = false;
        xx_slctdElemId = x_slctdElemId + "-panel";
    }
    // console.log(isConnector, selectedElement, xx_slctdElemId); 
    
    let zero = 0;
    let currentId = null;

    function dummySetterFunction(paramX,paramY,moveX,moveY,dumParam){        
        x_setDummyElementStyle(preVdummy => ({
            ...preVdummy, 
            [paramX]: rectify(parseFloat(dumParam[paramX]) + moveX) + 'px',
            [paramY]: rectify(parseFloat(dumParam[paramY]) + moveY) + 'px'
        }));
    }
        
    function uponMsMv(x_isInvalidVar, x_overlapId) {
        x_setWrnFlag(!x_isInvalidVar);
        x_setClsInvld(x_isInvalidVar);
        if (x_overlapId !== currentId) {
            addHighlightClass(false, currentId); // Clearing previous element's color
            currentId = x_overlapId;
            addHighlightClass(true, currentId); // Setting new element's color
            addVanishClass(!!currentId,'dobjct-copy'); //setting dobjt-copy vanish
        }
    }

    function uponMsUp(x_varX,x_varY,x_dummyInitParams,x_isInvalidVar,x_overlapId) {

        addHighlightClass(false, currentId); // Always clear on mouse up
        if(x_isInvalidVar) {
            if (isNew) {x_setSlctdElemId('newElement0');}
            else {dummySetterFunction(x_varX,x_varY,zero,zero,x_dummyInitParams);}
        }
        else {
            if (isNew) {
                if (x_overlapId) {x_setSlctdElemId('newLane1' + x_overlapId);}
                else {x_setSlctdElemId('newLane0');}
            }
        }
        x_setClsInvld(false);
        x_setDrgFnsh(prevState => prevState+1);
        x_setWrnFlag(false);
    }    

    function funcDrag(e,varX,varY,setterFunc,x_uponMsMv,x_uponMsUp) {
        // console.log("dragResizeFunction start");
    
        let isInvalidVar;
        let overlapId;
        let startX = e.clientX ;
        let startY = e.clientY ;
        let dummyInitParams = {[varX]: x_dummyElementStyle[varX], [varY]: x_dummyElementStyle[varY]};
    
        window.addEventListener("mousemove", funcMouseMove);
        window.addEventListener("mouseup", funcMouseUp);
    
        function funcMouseMove(e){
            let moveX = e.clientX - startX; moveX = discretize10(moveX);
            let moveY = e.clientY - startY; moveY = discretize10(moveY);
    
            setterFunc(varX,varY,moveX,moveY,dummyInitParams);            

            overlapId = isNew ? idUnderCursor(e, 'restLaneClass') : null ;
            isInvalidVar = !overlapId && (isInvalidFunc(selectedElement, xx_slctdElemId, "swimLanePanelClass") || 
                isInvalidFunc(selectedElement.nextSibling, xx_slctdElemId, "swimLanePanelClass"));

            x_uponMsMv(isInvalidVar, overlapId);
            // create separate mouse moves for new and old
        }
    
        function funcMouseUp(){        
            window.removeEventListener("mousemove", funcMouseMove);
            window.removeEventListener("mouseup", funcMouseUp);  
            
            x_uponMsUp(varX,varY,dummyInitParams,isInvalidVar,overlapId);           
            // console.log("dragResizeFunction stop");           
        }
    }

    funcDrag(e,'left','top',dummySetterFunction,uponMsMv,uponMsUp);
    
}

export {dragResizeFunction3};