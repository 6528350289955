import { isInvalidFunc, discretize10, rectifyLaneWd, rectifyLaneHt } from "./Mn_CanvasCompUtil_func";

function dragResizeFunction4 (e, x_slctdElemId, x_setSlctdElemId, x_dummyElementStyle, x_setDummyElementStyle, x_setDrgFnsh, x_setClsInvld, x_setWrnFlag) {
    e.stopPropagation();
    e.preventDefault();
    let selectedElement = e.target;
    let xx_slctdElemId = (x_slctdElemId.slice(3) === 'newElement') ? x_slctdElemId.slice(3) : x_slctdElemId ;
    // console.log(isConnector, selectedElement, xx_slctdElemId); 
    
    let zero = 0;

    function dummySetterFunction(paramX,paramY,moveX,moveY,dumParam){        
        x_setDummyElementStyle((preVdummy)=>{ return {...preVdummy, 
            [paramX]: rectifyLaneWd(parseFloat(dumParam[paramX])+moveX)+'px',
            [paramY]: rectifyLaneHt(parseFloat(dumParam[paramY])+moveY)+'px'
        }})        
    }  
        
    function uponMsMv(x_isInvalidVar) {
        x_setWrnFlag(!x_isInvalidVar);
        x_setClsInvld(x_isInvalidVar);
    }

    function uponMsUp(x_varX,x_varY,x_dummyInitParams,x_isInvalidVar) {
        if(x_isInvalidVar) {
            if (xx_slctdElemId === 'newElement') {x_setSlctdElemId('newElement0');}
            else {dummySetterFunction(x_varX,x_varY,zero,zero,x_dummyInitParams);}
        }
        else {
            if (xx_slctdElemId === 'newElement') {x_setSlctdElemId('newElement');}
        }
        x_setClsInvld(false);
        x_setDrgFnsh(prevState => prevState+1);
        x_setWrnFlag(false);
    }

    function funcResize(e,varX,varY,setterFunc,x_uponMsMv,x_uponMsUp) {
        // console.log("dragResizeFunction start");
    
        let isInvalidVar;
        let startX = e.clientX ;
        let startY = e.clientY ;
        let dummyInitParams = {[varX]: x_dummyElementStyle[varX], [varY]: x_dummyElementStyle[varY]};
    
        window.addEventListener("mousemove", funcMouseMove);
        window.addEventListener("mouseup", funcMouseUp);
    
        function funcMouseMove(e){
            let moveX = e.clientX - startX; moveX = discretize10(moveX);
            let moveY = e.clientY - startY; moveY = discretize10(moveY);
    
            setterFunc(varX,varY,moveX,moveY,dummyInitParams);
            
            isInvalidVar = isInvalidFunc(selectedElement, xx_slctdElemId, "laneAnchorClass")
            x_uponMsMv(isInvalidVar);         
        }
    
        function funcMouseUp(){        
            window.removeEventListener("mousemove", funcMouseMove);
            window.removeEventListener("mouseup", funcMouseUp);  
            
            x_uponMsUp(varX,varY,dummyInitParams,isInvalidVar);           
            // console.log("dragResizeFunction stop");           
        }
    }

    funcResize(e,'width','height',dummySetterFunction,uponMsMv,uponMsUp);
    
}

export {dragResizeFunction4};