import React from 'react';
import { discretize5 } from '../subcomponents/Mn_CanvasCompUtil_func';

// Gradient Definition Component
const GradientDef1 = () => (
  <defs>
    <linearGradient id="grad3" x1="0%" y1="0%" x2="80%" y2="80%">
      <stop offset="0%" style={{ stopColor: 'rgb(255,255,255)', stopOpacity: 1 }} />
      <stop offset="100%" style={{ stopColor: 'rgb(255,255,200)', stopOpacity: 1 }} />
    </linearGradient>
  </defs>
);

const GradientDef2 = () => (
  <defs>
      <linearGradient id="grad4" x1="0%" y1="0%" x2="80%" y2="80%">
          <stop offset="0%" style={{stopColor:'rgb(240,240,240)', stopOpacity:1}} />
          <stop offset="100%" style={{stopColor:'rgb(200,200,200)', stopOpacity:1}} />
      </linearGradient>
  </defs>
);

// Function to Calculate Points
const calculatePoints12 = (width, height) => {
  const innerX = 5;
  const innerY = 5;
  const innerWidth = width - 10;
  const innerHeight = height - 10;

  return [
    // Top Edge Points
    [discretize5(innerX + innerWidth / 6), innerY],
    [discretize5(innerX + innerWidth / 2), innerY],
    [discretize5(innerX + 5 * innerWidth / 6), innerY],
    // Right Edge Points
    [innerX + innerWidth, discretize5(innerY + innerHeight / 6)],
    [innerX + innerWidth, discretize5(innerY + innerHeight / 2)],
    [innerX + innerWidth, discretize5(innerY + 5 * innerHeight / 6)],
    // Bottom Edge Points
    [discretize5(innerX + innerWidth / 6), innerY + innerHeight],
    [discretize5(innerX + innerWidth / 2), innerY + innerHeight], 
    [discretize5(innerX + 5 * innerWidth / 6), innerY + innerHeight],       
    // Left Edge Points
    [innerX, discretize5(innerY + innerHeight / 6)],
    [innerX, discretize5(innerY + innerHeight / 2)],
    [innerX, discretize5(innerY + 5 * innerHeight / 6)],    
  ];
};

const calculatePoints4 = (width, height) => {
  const innerX = 5;
  const innerY = 5;
  const innerWidth = width - 10;
  const innerHeight = height - 10;

  return [
    // Top Edge Points
    [discretize5(innerX + innerWidth / 2), innerY],
    // Right Edge Points
    [innerX + innerWidth, discretize5(innerY + innerHeight / 2)],
    // Bottom Edge Points
    [discretize5(innerX + innerWidth / 2), innerY + innerHeight],     
    // Left Edge Points
    [innerX, discretize5(innerY + innerHeight / 2)],  
  ];
};

// Circle Component to Draw Points
const CirclePoint = ({ cx, cy }) => (
  <circle r="3" cx={cx} cy={cy} fill="white" stroke="gray" strokeWidth="1" vectorEffect="non-scaling-stroke"/> 
);

// Circle Points Component
const CirclePoints = ({ width, height, number_points }) => {
  let points
  switch (number_points) {
    case 12 :
      points = calculatePoints12(width, height); break;
    case 4 :
      points = calculatePoints4(width, height); break;
    default :
      points = []
  }

  return (
    <>
      {points.map((point, index) => (
        <CirclePoint key={index} cx={point[0]} cy={point[1]} />
      ))}
    </>
  );
};

// Common Rectangle Component
const CommonRect = ({ fill, strokeDasharray, width, height }) => (
  <rect x="5" y="5" width={width - 10} height={height - 10} rx="10" ry="10" fill={fill} stroke="black" strokeWidth="1" strokeDasharray={strokeDasharray} />
);

// ********** Activities **********

// SVG 100 Component
const Svg100 = ({ width, height, isHover }) => (
  <>
    <GradientDef1 />
    <CommonRect fill="url(#grad3)" width={width} height={height} />
    {isHover && <CirclePoints width={width} height={height} number_points={12}/>}
  </>
);

// SVG 101 Component
const Svg101 = ({ width, height, isHover }) => {
  const halfWidth = Math.ceil(width / 2);
  return (
    <>
      <GradientDef1 />
      <CommonRect fill="url(#grad3)" width={width} height={height} />
      <rect x={halfWidth - 10} y={height - 25} width="20" height="20" fill="none" stroke="black" strokeWidth="1.5" />
      <line x1={halfWidth} y1={height - 21} x2={halfWidth} y2={height - 9} stroke="black" strokeWidth="1.5" />
      <line x1={halfWidth - 6} y1={height - 15} x2={halfWidth + 6} y2={height - 15} stroke="black" strokeWidth="1.5" />
      <CommonRect fill="white" width={width} height={height} />
      {isHover && <CirclePoints width={width} height={height} number_points={12}/>}
    </>
  );
};

// SVG 102 Component
const Svg102 = ({ width, height, isHover }) => (
  <>
    <CommonRect fill="white" strokeDasharray="2,2" width={width} height={height} />
    {isHover && <CirclePoints width={width} height={height} number_points={12}/>}
  </>
);

// SVG 103 Component
const Svg103 = ({ width, height, isHover }) => {
  const halfWidth = Math.ceil(width / 2);
  return (
    <>
      <CommonRect fill="white" strokeDasharray="2,2" width={width} height={height} />
      <svg x="10" y="10" width="40" height="40" viewBox="0 0 100 100">
        <circle r="40" cx="50" cy="50" fill="none" stroke="black" strokeWidth="1" />
        <polygon points="30,38 30,62 70,62 70,38 30,38 50,50 70,38" fill="white" stroke="black" strokeWidth="1" />
      </svg>
      <rect x={halfWidth - 10} y={height - 25} width="20" height="20" fill="none" stroke="black" strokeWidth="1.5" />
      <line x1={halfWidth} y1={height - 21} x2={halfWidth} y2={height - 9} stroke="black" strokeWidth="1.5" />
      <line x1={halfWidth - 6} y1={height - 15} x2={halfWidth + 6} y2={height - 15} stroke="black" strokeWidth="1.5" />
      {isHover && <CirclePoints width={width} height={height} number_points={12}/>}
    </>
  );
};

// SVG 104 Component
const Svg104 = ({ width, height, isHover }) => (
  <>
    <CommonRect fill="white" width={width} height={height} />
    {isHover && <CirclePoints width={width} height={height} number_points={12}/>}
  </>
);

// ********** Gateways **********

// SVG 110 Component
const Svg110 = ({ width, height, isHover }) => (
  <>
    <polygon points="5,25 25,45 45,25 25,5" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    <line x1="18" y1="16.5" x2="32" y2="33.5" stroke="black" strokeWidth="4" vectorEffect="non-scaling-stroke"/>
    <line x1="18" y1="33.5" x2="32" y2="16.5" stroke="black" strokeWidth="4" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 111 Component
const Svg111 = ({ width, height, isHover }) => (
  <>
    <polygon points="5,25 25,45 45,25 25,5" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    <line x1="25" y1="15" x2="25" y2="35" stroke="black" strokeWidth="3" vectorEffect="non-scaling-stroke"/>
    <line x1="15" y1="25" x2="35" y2="25" stroke="black" strokeWidth="3" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 112 Component
const Svg112 = ({ width, height, isHover }) => (
  <>
    <polygon points="5,25 25,45 45,25 25,5" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    <circle r="12" cx="25" cy="25" fill="none" stroke="black" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
    <polygon points="25,16 16,21 19,32 31,32 34,21" fill="none" stroke="black" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 113 Component
const Svg113 = ({ width, height, isHover }) => (
  <>
    <polygon points="5,25 25,45 45,25 25,5" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    <circle r="10" cx="25" cy="25" fill="none" stroke="black" strokeWidth="3" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 114 Component
const Svg114 = ({ width, height, isHover }) => (
  <>
    <polygon points="5,25 25,45 45,25 25,5" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    <line x1="17.5" y1="17.5" x2="32.5" y2="32.5" stroke="black" strokeWidth="4" vectorEffect="non-scaling-stroke"/>
    <line x1="17.5" y1="32.5" x2="32.5" y2="17.5" stroke="black" strokeWidth="4" vectorEffect="non-scaling-stroke"/>
    <line x1="25" y1="15" x2="25" y2="35" stroke="black" strokeWidth="4" vectorEffect="non-scaling-stroke"/>
    <line x1="15" y1="25" x2="35" y2="25" stroke="black" strokeWidth="4" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// ********** Data Objects **********

// SVG 130 Component
const Svg130 = ({ width, height, isHover }) => {
  const x2 = width - 5;
  const x4 = width - 10;
  const y2 = height - 5;
  const points = `${x4},5 ${x2},10 ${x4},10 ${x4},5 5,5 5,${y2} ${x2},${y2} ${x2},10`;

  return (
    <polygon points={points} fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke" />
  );
};

// SVG 131 Component
const Svg131 = ({ width, height, isHover }) => {
  const x2 = Math.ceil(width / 2);
  const x3 = width - 5;
  const rx = x2 - 5;
  const ystep = Math.ceil((height - 10)/7);
  const y2 = 5 + ystep;
  const y3 = 5 + Math.ceil(1.5 * ystep);
  const y4 = 5 + (2 * ystep);
  const y5 = height - 5 - ystep;

  return (
    <>
      <ellipse cx={x2} cy={y5} rx={rx} ry={ystep} fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
      <rect x="5" y={y2} height={y5 - y2} width={width - 2} fill="white" stroke="white" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
      <ellipse cx={x2} cy={y4} rx={rx} ry={ystep} fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
      <ellipse cx={x2} cy={y3} rx={rx} ry={ystep} fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
      <ellipse cx={x2} cy={y2} rx={rx} ry={ystep} fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
      <line x1="5" y1={y4} x2="5" y2={y5} stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
      <line x1={x3} y1={y4} x2={x3} y2={y5} stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    </>
  );
};

// SVG 132 Component
const Svg132 = ({ width, height, isHover }) => {
  const x2 = Math.ceil(width / 2);
  const x3 = width - 5;
  const y2 = Math.ceil(0.6 * height);
  const y3 = height - 5;
  const points = `5,5 5,${y3} ${x3},${y3} ${x3},5 5,5 ${x2},${y2} ${x3},5`;

  return (
    <polygon points={points} fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
  );
};

// ********** Artifacts **********

// SVG 140 Component
const Svg140 = ({ width, height, isHover }) => {
  const x3 = 10 + Math.floor((width - 20)/16);
  const x3w = Math.ceil(0.875 * (width - 20));
  const x4 = 10 + Math.ceil((width - 20)/4);
  const x5 = 10 + Math.ceil(0.75 * (width - 20));
  const h1 = Math.ceil(0.6875 * (height - 20));
  const h2 = Math.ceil(0.3125 * (height - 20));
  const h3 = Math.ceil(0.1875 * (height - 20));
  const y2 = 10 + Math.ceil(0.14545 * h1);
  const y2h = Math.ceil(0.709 * h1);
  const y4 = 10 + h1;
  const y5 = height - 5 - h2;
  const y6 = height - 5 - h3;

  return (
    <>
      <GradientDef2 />
      <rect x="10" y="10" height={h1} width={width - 20} fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
      <rect x={x3} y={y2} height={y2h} width={x3w} fill="url(#grad4)" stroke="gray" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
      <rect x="5" y={y5} height={h2} width={width - 10} fill="none" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
      <line x1={x5} y1={y6} x2={width - 10} y2={y6} stroke="rgb(180,180,180)" strokeWidth="3" vectorEffect="non-scaling-stroke"/>
      <line x1={x4} y1={y4} x2={x4} y2={y5} stroke="gray" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
      <line x1={x5} y1={y4} x2={x5} y2={y5} stroke="gray" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
    </>
  );
};

// SVG 141 Component
const Svg141 = ({ width, height, isHover }) => (
  <rect x="5" y="5" height={height - 10} width={width - 10} rx="10" ry="10" fill="none" stroke="black" strokeWidth="1" stroke-dasharray="5,3,2,3" vectorEffect="non-scaling-stroke"/>
);

// SVG 142 Component
const Svg142 = ({ width, height, isHover }) => {
  const points = `${width - 5},5 5,5 5,${height - 5} ${width - 5},${height - 5}`;

  return (
    <polyline points={points} fill="none" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
  );
};

// ********** Start Events **********

// SVG 150 Component
const Svg150 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 151 Component
const Svg151 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    <polygon points="15,19 15,31 35,31 35,19 15,19 25,25 35,19" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 152 Component
const Svg152 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    <circle r="15" cx="25" cy="25" fill="none" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    <line x1="25" y1="25" x2="32.5" y2="25" stroke="gray" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
    <line x1="25" y1="25" x2="32.5" y2="16.5" stroke="gray" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 153 Component
const Svg153 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    <polygon points="22,15 15,35 22,24 28,35 35,15 28,26" fill="none" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 154 Component
const Svg154 = ({ width, height, isHover }) => (
  <>
     <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    <polygon points="33,15 23.5,25 33.5,35" fill="none" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    <polygon points="25,15 15,25 25,35" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 155 Component
const Svg155 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    <polygon points="21.5,11.5 21.5,21.5 11.5,21.5 11.5,28.5 21.5,28.5 21.5,38.5 28.5,38.5 28.5,28.5 38.5,28.5 38.5,21.5 28.5,21.5 28.5,11.5" fill="none" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 156 Component
const Svg156 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    <polygon points="25,15 16,34 25,25 34,34" fill="none" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 157 Component
const Svg157 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    <polygon points="15,14 15,36 35,36 35,14" fill="none" stroke="gray" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <line x1="17.5" y1="17.5" x2="32.5" y2="17.5" stroke="gray" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <line x1="17.5" y1="22.5" x2="32.5" y2="22.5" stroke="gray" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <line x1="17.5" y1="27.5" x2="32.5" y2="27.5" stroke="gray" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <line x1="17.5" y1="32.5" x2="32.5" y2="32.5" stroke="gray" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 158 Component
const Svg158 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    <polygon points="25,15 16.5,31 33.5,31" fill="none" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 159 Component
const Svg159 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    <polygon points="25,14.5 15,21 18.5,33 31.5,33 35,21" fill="none" stroke="black" strokeWidth="1" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// ********** End Events **********

// SVG 160 Component
const Svg160 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="3"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 161 Component
const Svg161 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="3"/>
    <polygon points="25,15 16,34 25,25 34,34" fill="onyx" stroke="black" strokeWidth="1.5"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 162 Component
const Svg162 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="3"/>
    <polygon points="15,19 15,31 35,31 35,19 15,19 25,25 35,19" fill="onyx" stroke="white" strokeWidth="1"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 163 Component
const Svg163 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="3"/>
    <polygon points="22,15 15,35 22,24 28,35 35,15 28,26" fill="black" stroke="black" strokeWidth="1"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 164 Component
const Svg164 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="3"/>
    <line x1="17.5" y1="17.5" x2="32.5" y2="32.5" stroke="black" strokeWidth="3.5"/>
    <line x1="17.5" y1="32.5" x2="32.5" y2="17.5" stroke="black" strokeWidth="3.5"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 165 Component
const Svg165 = ({ width, height, isHover }) => (
  <>
     <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="2"/>
    <polygon points="33,15 23.5,25 33.5,35" fill="black" stroke="black" strokeWidth="1.5"/>
    <polygon points="25,15 15,25 25,35" fill="black" stroke="black" strokeWidth="1.5"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 166 Component
const Svg166 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="2"/>
    <polygon points="25,15 16.5,31 33.5,31" fill="black" stroke="black" strokeWidth="2"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 167 Component
const Svg167 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="2"/>
    <polygon points="25,14.5 15,21 18.5,33 31.5,33 35,21" fill="black" stroke="black" strokeWidth="2"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 168 Component
const Svg168 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="2"/>
    <circle r="14" cx="25" cy="25" fill="black" stroke="black" strokeWidth="1"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// ********** Catching Indermediate Events **********

// SVG 170 Component
const Svg170 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <polygon points="21.5,11.5 21.5,21.5 11.5,21.5 11.5,28.5 21.5,28.5 21.5,38.5 28.5,38.5 28.5,28.5 38.5,28.5 38.5,21.5 28.5,21.5 28.5,11.5" fill="white" stroke="black" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 171 Component
const Svg171 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="white" stroke="black" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
    <polygon points="25,15 16,34 25,25 34,34" fill="white" stroke="black" strokeWidth="3" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 172 Component
const Svg172 = ({ width, height, isHover }) => (
  <>
     <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="white" stroke="black" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
    <polygon points="15,19 15,31 35,31 35,19 15,19 25,25 35,19" fill="white" stroke="black" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 173 Component
const Svg173 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="none" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <circle r="15" cx="25" cy="25" fill="none" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <line x1="25" y1="25" x2="32.5" y2="25" stroke="black" strokeWidth="3" vectorEffect="non-scaling-stroke"/>
    <line x1="25" y1="25" x2="32.5" y2="16.5" stroke="black" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 174 Component
const Svg174 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <polygon points="22,15 15,35 22,24 28,35 35,15 28,26" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 175 Component
const Svg175 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <polygon points="21.5,11.5 21.5,21.5 11.5,21.5 11.5,28.5 21.5,28.5 21.5,38.5 28.5,38.5 28.5,28.5 38.5,28.5 38.5,21.5 28.5,21.5 28.5,11.5" transform="rotate(45, 25, 25)" fill="white" stroke="black" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 176 Component
const Svg176 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.8" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.8" vectorEffect="non-scaling-stroke"/>
    <polygon points="33,15 23.5,25 33.5,35" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <polygon points="25,15 15,25 25,35" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 177 Component
const Svg177 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <polygon points="15,14 15,36 35,36 35,14" fill="white" stroke="black" strokeWidth="2"/>
    <line x1="17.5" y1="17.5" x2="32.5" y2="17.5" stroke="black" strokeWidth="2"/>
    <line x1="17.5" y1="22.5" x2="32.5" y2="22.5" stroke="black" strokeWidth="2"/>
    <line x1="17.5" y1="27.5" x2="32.5" y2="27.5" stroke="black" strokeWidth="2"/>
    <line x1="17.5" y1="32.5" x2="32.5" y2="32.5" stroke="black" strokeWidth="2"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 178 Component
const Svg178 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <polygon points="25,15 16.5,31 33.5,31" fill="white" stroke="black" strokeWidth="3"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 179 Component
const Svg179 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <polygon points="25,14.5 15,21 18.5,33 31.5,33 35,21" fill="white" stroke="black" strokeWidth="3"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 1700 Component
const Svg1700 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <polygon points="13.5,21 13.5,29 30,29 30,34 39,25 30,16 30,21" fill="white" stroke="black" strokeWidth="1.5"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// ********** Throwing Indermediate Events **********

// SVG 180 Component
const Svg180 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <polygon points="25,15 16,34 25,25 34,34" fill="black" stroke="black" strokeWidth="1.5"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 181 Component
const Svg181 = ({ width, height, isHover }) => (
  <>
     <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
     <circle r="16.5" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
     {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 182 Component
const Svg182 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <polygon points="15,19 15,31 35,31 35,19 15,19 25,25 35,19" fill="black" stroke="white" strokeWidth="1.5"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 183 Component
const Svg183 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="transparent" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="transparent" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <polygon points="33,15 23.5,25 33.5,35" fill="black" stroke="black" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
    <polygon points="25,15 15,25 25,35" fill="black" stroke="black" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 184 Component
const Svg184 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <polygon points="25,15 16.5,31 33.5,31" fill="black" stroke="black" strokeWidth="2"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 185 Component
const Svg185 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <polygon points="25,14.5 15,21 18.5,33 31.5,33 35,21" fill="black" stroke="black" strokeWidth="2"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

// SVG 186 Component
const Svg186 = ({ width, height, isHover }) => (
  <>
    <circle r="20" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <circle r="16.5" cx="25" cy="25" fill="white" stroke="black" strokeWidth="1.5" vectorEffect="non-scaling-stroke"/>
    <polygon points="13.5,21 13.5,29 30,29 30,34 39,25 30,16 30,21" fill="black" stroke="black" strokeWidth="1.5"/>
    {isHover && <CirclePoints width={width} height={height} number_points={4}/>
}  </>
);

const svgContents = {
  100: Svg100,
  101: Svg101,
  102: Svg102,
  103: Svg103,
  104: Svg104,
  110: Svg110,
  111: Svg111,
  112: Svg112,
  113: Svg113,
  114: Svg114,
  130: Svg130,
  131: Svg131,
  132: Svg132,
  140: Svg140,
  141: Svg141,
  142: Svg142,
  150: Svg150,
  151: Svg151,
  152: Svg152,
  153: Svg153,
  154: Svg154,
  155: Svg155,
  156: Svg156,
  157: Svg157,
  158: Svg158,
  159: Svg159,
  160: Svg160,
  161: Svg161,
  162: Svg162,
  163: Svg163,
  164: Svg164,
  165: Svg165,
  166: Svg166,
  167: Svg167,
  168: Svg168,
  170: Svg170,
  171: Svg171,
  172: Svg172,
  173: Svg173,
  174: Svg174,
  175: Svg175,
  176: Svg176,
  177: Svg177,
  178: Svg178,
  179: Svg179,
  1700:Svg1700,
  180: Svg180,
  181: Svg181,
  182: Svg182,
  183: Svg183,
  184: Svg184,
  185: Svg185,
  186: Svg186

};

export default svgContents;
