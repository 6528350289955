export default function ToolBarComp({data, functionMap}) {

    const list_tool_data_mod = data.map((elem)=>{

        const img_tag1 = <img key={elem.id+'a'}src={`/project_svg/toolbar_svg/${elem.img1}.svg`} alt={elem.img1} width="20px" onClick={functionMap[elem.id]}/>;
        const img_tag2 = <img key={elem.id+'b'}className="dropImg" src={`/project_svg/app_svg/${elem.img2}.svg`} alt={elem.img2} width="8px"/>;

        const img_tag_array = [img_tag1, elem.img2 && img_tag2];
    
        return(
            <div key={elem.id}>             
                {img_tag_array}
            </div>
        )
    })

    return (
        <div className="toolcat">
            {list_tool_data_mod}
        </div>
    );
  }
  