import { list_c } from "./dataSidePanel";

let idSvgMap = {};

for (let x of list_c){
    for (let y of x.data){
        let {id, txt} = y;
        idSvgMap = {...idSvgMap, [id]: id + txt.slice(0,3).trim()};
    }
}

function wdtHtFnc (id) {
    let width, height;
    switch(Number(id.toString().slice(0,2))) {
      case 10:
        if(id === 102 | id === 104){
          width='200px';
          height='160px';
        }
        else {
          width='110px';
          height='90px';
        }
        break;
      case 11:
        width='50px';
        height='50px';
        break;
      case 12:
        width='620px';
        height='300px';
        break;
      case 13:
        switch(id) {
          case 130:
            width='100px';
            height='120px';
            break;
          case 131:
            width='80px';
            height='60px';
            break;
          default:
            width='50px';
            height='40px';
        }
        break;
        case 14:
        switch(id) {
          case 140:
            width='70px';
            height='70px';
            break;
          case 141:
            width='130px';
            height='100px';
            break;
          default:
            width='40px';
            height='70px';
        }
        break;
      default:
        width='50px';
        height='50px';
    }
    return {width: width, height: height}
  }

  const IsCnctrFunc = id=>id.slice(0,2)==='19';
  const cnctrType = id=>Number(id.slice(2,3));
  // typ = { 0 : "Sequence Flow", 1 : "Association (Undirected)", 2 : "Association (Unidirectional)", 3 : "Message Flow", 4 : "Association (Bidirectional)" }
  const IsLaneFunc = id=>id.slice(0,2)==='12';
  const IsActivitsFunc = id=>id.slice(0,2)==='10';
  const IsDataObjFunc = id=>id.slice(0,3)==='130';

  function isParentLaneFunc (x_id) {
    if (typeof x_id !== 'string') {
        console.error('Invalid id provided:', x_id);
        return; // Exit the function early if id is invalid
    }
    return x_id.slice(0,3) === '120';
  }
  
  function isChildLaneFunc (x_id) {
    if (typeof x_id !== 'string') {
        console.error('Invalid id provided:', x_id);
        return; // Exit the function early if id is invalid
    }
    return x_id.slice(0,3) === '125';
  }
  
  function isSingleLaneFunc (x_id) {
    if (typeof x_id !== 'string') {
        console.error('Invalid id provided:', x_id);
        return; // Exit the function early if id is invalid
    }
    return x_id.slice(0,3) === '129';
  }

  class StyleParam {
    constructor (left, top, width, height, backgroundImage) {
      this.left = left;
      this.top = top;
      this.width = width;
      this.height = height;
      this.backgroundImage = backgroundImage;
    }
  }

  class LineSvgParams {
    constructor (ids, pts) {
      this.ids = ids;
      this.pts = pts;
  }}
  
  class GlobalElement {
    constructor (glbElemId, IsRsz, style) {
      this.glbElemId = glbElemId;
      this.IsRsz = IsRsz;
      this.style = style;
    }
  }

  class GlobalText {
    constructor (txt, txtStl, show) {
      this.txt = txt;
      this.txtStl = txtStl;
      this.show = show;
    }
  }

  class GlobalIdMap {
    constructor (idis, idfs) {
      this.idis = idis;
      this.idfs = idfs;
    }
  }

  class swimLanePropsB {
    constructor (title, laneIds) {
        this.title = title;      
        this.laneIds = laneIds;
    }
}

class swimLanePropsA extends swimLanePropsB{
    constructor (title, position, length, laneIds) {
        super(title, laneIds);
        this.position = position;
        this.length = length;

    }
}

  class singleLaneProps {
    constructor (name, height, idList) {
        this.name = name;
        this.height = height;
        this.idList = idList;
    }
  }

  class laneAnchor {
    constructor (left, top, width, height) {
      this.left = left;
      this.top = top;
      this.width = width;
      this.height = height;
    }
  }

  const dummyClassCreator = (_int,_bool) => {
    let result;
    switch (_int) {
      case 20:
        result = `dobjct-copy dummyObject2 ${_bool?'clsInvld':'clsvld'}`;
        break;
      case 30:
        result = `dobjct-copy dummyObject30 ${_bool?'clsInvld':'clsvld'}`;
        break;
      case 31:
        result = `dobjct-copy dummyObject31 ${_bool?'clsInvld':'clsvld'}`;
        break;
      case 32:
        result = `dobjct-copy dummyObject32 ${_bool?'clsInvld':'clsvld'}`;
        break;
      default:
        result = `dobjct-copy dummyObject2 ${_bool?'clsInvld':'clsvld'}`;
    }
    return result;
  }

export {idSvgMap, wdtHtFnc, IsCnctrFunc, cnctrType, IsLaneFunc, IsActivitsFunc, IsDataObjFunc, isParentLaneFunc, isChildLaneFunc, isSingleLaneFunc, StyleParam, LineSvgParams, GlobalElement, GlobalText, GlobalIdMap,
  swimLanePropsB, swimLanePropsA, singleLaneProps, laneAnchor, dummyClassCreator
};