import { useMyContextMn } from "../../Master"


export default function CanvasSize() {

  const {increaseWidthFunc, decreaseWidthFunc, increaseHeightFunc, decreaseHeightFunc} = useMyContextMn()
  
  return (
    <>    
        <div className="vertical-strip">
            <div className="addWidthButton" onClick={increaseWidthFunc}><img src="/project_svg/app_svg/canvas_size.svg" alt="angle" width="15px" /></div>
            <div className="subWidthButton" onClick={decreaseWidthFunc}><img src="/project_svg/app_svg/canvas_size.svg" alt="angle" width="15px" /></div>         
        </div>

        <div className="horizontal-strip">
            <div className="addHeightButton" onClick={increaseHeightFunc}><img src="/project_svg/app_svg/canvas_size.svg" alt="angle" width="15px" /></div>
            <div className="subHeightButton" onClick={decreaseHeightFunc}><img src="/project_svg/app_svg/canvas_size.svg" alt="angle" width="15px" /></div>  
        </div>
    </>    
  );
}