import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { baseLineStyle, calcAngleDegrees, calcDistance, crclHeadStyle, crclPointStyle, infoBoxStyle, rectHorStyle, rectVerStyle } from "./Mn_connector_func";
import { mouseDnStimulateParent } from "./Mn_CanvasCompUtil_func";

const ConnectorAnchors1Dummy = forwardRef(
    function (props, ref){

        const {id, lineStyle, handleMouseDown, insertPtFunc, insertTextFunc, handleTouch} = props;

        const crclTailD_Ref = useRef(null);
        const crclHeadD_Ref = useRef(null);
        const crclPtD_Refs = useRef([]);
        const rectD_Refs = useRef([]);
        const baseLine_Refs = useRef([]);
        
        let points = useMemo(() => [...lineStyle['pts']], [lineStyle]);
        let len = points.length;

        useEffect(() => {
            if (crclPtD_Refs.current.length !== len - 1) {
              crclPtD_Refs.current.length = len - 1;
            }
            if (rectD_Refs.current.length !== len - 2) {
              rectD_Refs.current.length = len - 2;
            }
            if (baseLine_Refs.current.length !== len - 1) {
              baseLine_Refs.current.length = len - 1;
            }
        }, [len]);
          

        useImperativeHandle(ref, () => ({
            hdMsDn: (coord, clBkSetFunc) => {
                let refElem = crclHeadD_Ref.current;
                if (refElem) {mouseDnStimulateParent(refElem, coord, clBkSetFunc);};
            },
            tlMsDn: (coord, clBkSetFunc) => {
                let refElem = crclTailD_Ref.current;
                if (refElem) {mouseDnStimulateParent(refElem, coord, clBkSetFunc);};
            },
            crclPtMsDn: (index, coord, clBkSetFunc1, clBkSetFunc2) => {
                let refElem = crclPtD_Refs.current[index];
                if (refElem) {mouseDnStimulateParent(refElem, coord, clBkSetFunc1, clBkSetFunc2);};
            },
            rectMsDn: (index, coord, clBkSetFunc1, clBkSetFunc2) => {
                let refElem = rectD_Refs.current[index];
                if (refElem) {mouseDnStimulateParent(refElem, coord, clBkSetFunc1, clBkSetFunc2);};
            },
            bsLnMsDn: (index, coord, clBkSetFunc1, clBkSetFunc2) => {
                let refElem = baseLine_Refs.current[index];
                if (refElem) {mouseDnStimulateParent(refElem, coord, clBkSetFunc1, clBkSetFunc2);};
            }
          }));

        let angl2 = calcAngleDegrees(points[len-2], points[len-1]);
        let trnsfrm2 = `rotate(${angl2}deg)`;

        const insertTextFuncParent = (e) => {
            e.stopPropagation();
            insertTextFunc(id)
        }

        const insertPtFuncParent = (e) => {
            e.stopPropagation();
            insertPtFunc(e, points)
        }

        const crclPoints = points.slice(1, -1).map((point, index) => {
            return (
                <div key={index} ref={el => (crclPtD_Refs.current[index + 1] = el)} className="crclAnchor crclPtD" id={'crclPtD-' + id + '-' + (index + 1)}  style={crclPointStyle(point)} ></div>
            );
        });

        const rectSlides = points.slice(1, len - 2).map((point, index) => {
            const nextPoint = points[index + 2];
            let isYsame;   

            // Check if the line segment is horizontal or vertical
            if (point[0] === nextPoint[0]) { isYsame = 0 } // Vertical segment
            else if (point[1] === nextPoint[1]) { isYsame = 1 } // Horizontal segment 
            else { return null; }

            if (Math.abs(point[1 - isYsame] - nextPoint[1 - isYsame]) < 40) {
                return null;
            }
            let midVal = (point[1 - isYsame] + nextPoint[1 - isYsame]) / 2;

            if (isYsame) {
                return (
                    <div key={index} ref={el => (rectD_Refs.current[index + 1] = el)} className="rectAnchor rectHorD" id={'rectAnchorHD-' + id + '-' + (index + 1)} style={rectHorStyle(point, midVal)} ></div>
                );
            } else {
                return (
                    <div key={index} ref={el => (rectD_Refs.current[index + 1] = el)} className="rectAnchor rectVerD" id={'rectAnchorVD-' + id + '-' + (index + 1)} style={rectVerStyle(point, midVal)} ></div>
                );
            }
        });

        const baseline = points.slice(0, -1).map((point, index) => {
            const nextPoint = points[index + 1];
            let lengt = calcDistance(point, nextPoint);
            let angl = calcAngleDegrees(point, nextPoint);
            let trnsf = `rotate(${angl}deg)`;

            return (
                <div key={index} ref={el => (baseLine_Refs.current[index] = el)} className="baselineAnchor baseLineD" id={'baseLineD-' + id + '-' + index} onDoubleClick={insertPtFuncParent} style={baseLineStyle(point, lengt, trnsf)} ></div>
            );
        });

        return (
            <div className="connector-parent" onMouseDown={handleMouseDown} >
                {baseline}
                {crclPoints}
                <div className="crclAnchor crclTailD" id={'crclTailD-' + id + '-0'} ref={crclTailD_Ref} style={crclPointStyle(points[0])} ></div>
                <div className="crclAnchor crclHeadD" id={'crclHeadD-' + id + (len-1)} ref={crclHeadD_Ref} onMouseEnter={() => {handleTouch(infoBoxStyle(points[len-1]))}} onDoubleClick={insertTextFuncParent} style={crclHeadStyle(points[len - 1], trnsfrm2)} ></div>
                {rectSlides}
            </div>
        );
    });

export default ConnectorAnchors1Dummy;
