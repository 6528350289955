import React from "react";
import { useMyContextMn } from '../../Master';
import { findLaneHeight } from "./Mn_CanvasCompUtil_func";
import { isChildLaneFunc, isParentLaneFunc, isSingleLaneFunc, swimLanePropsA, laneAnchor } from "../data/stdClsFnc";

function SLaneAnchorComp ({x_id, slane, insertTextFunc, handleMouseDown}) {

  const {globalLanes} = useMyContextMn();

    let {title, position, length, laneIds} = slane;
    let sLaneHeight = findLaneHeight(x_id, globalLanes);
    let laneOrdinate = position[1];
    let IsParentLane = isParentLaneFunc(x_id);

    let swimLanePanel = new laneAnchor( position[0] + 'px', position[1] + 'px', (length + 20) + "px", sLaneHeight + 'px' );
    let swimLaneTitleStyle = new laneAnchor( (position[0] + 1) + 'px', (position[1] + (IsParentLane?1:20)) + 'px', "18px", (sLaneHeight - (IsParentLane?2:40)) + 'px' );
    let swimLaneTitleStyleOpaque = new laneAnchor( (position[0] + 1) + 'px', (position[1] + 1) + 'px', "18px", (sLaneHeight - 2) + 'px' );
    let swimLaneTitleStyleHlt = new laneAnchor( (position[0] + 6) + 'px', position[1] + 'px', "14px", sLaneHeight + 'px' );
    let swimLaneTitleStyleUp = new laneAnchor( position[0] + 'px', position[1] + 'px', "20px", "20px" );
    let swimLaneTitleStyleUpHlt = new laneAnchor( position[0] + 'px', position[1] + 'px', (length + 20) + 'px', "20px" );
    let swimLaneTitleStyleDown = new laneAnchor( position[0] + 'px', (position[1] + sLaneHeight - 20) + 'px', "20px", "20px" );
    let swimLaneTitleStyleDownHlt = new laneAnchor( position[0] + 'px', (position[1] + sLaneHeight - 20) + 'px', (length + 20) + 'px', "20px" );
    

    const subLaneAnchors = laneIds.map((laneId, index) => {

      if (isSingleLaneFunc(laneId)) {
        let {name, height} = globalLanes[laneId];
        let laneStyleLeft = new laneAnchor((position[0] + 20) + 'px', (laneOrdinate + 20) + 'px', '20px', (height - 40) + 'px');
        let laneStyleLeftHlt = new laneAnchor((position[0] + 20) + 'px', laneOrdinate + 'px', '20px', height + 'px');
        let laneStyleBody = new laneAnchor((position[0] + 40) + 'px', laneOrdinate + 'px', (length - 20) + 'px', height + 'px');
        let laneStyleUp = new laneAnchor((position[0] + 20) + 'px', laneOrdinate + 'px', length + "px", "20px");
        let laneStyleDown = new laneAnchor((position[0] + 20) + 'px', (laneOrdinate + height - 20) + 'px', length + "px", "20px");
        let laneStyleResize = new laneAnchor((position[0] + length) + 'px', (laneOrdinate + height - 20) + 'px', "18px", "18px");
        laneOrdinate += height;
        return <React.Fragment key={index}>
          <div id={laneId+'-'+x_id+'-Left'} className="subLaneClass laneTxtCls restLaneClass titleLaneAnchor" style={laneStyleLeft} onMouseDown={handleMouseDown} onDoubleClick={()=>insertTextFunc(laneId)}><div className="vertical-text">{name}</div></div>
          <div id={laneId+'-'+x_id+'-Left-Hlt'} className="subLaneClass" style={laneStyleLeftHlt}></div>
          <div id={laneId+'-'+x_id+'-Body'} className="subLaneClass restLaneClass" style={laneStyleBody}></div>
          <div id={laneId+'-'+x_id+'-Up'} className="subLaneClass restLaneClass" style={laneStyleUp}></div>
          <div id={laneId+'-'+x_id+'-Up-Hlt'} className="subLaneClass" style={laneStyleUp}></div>
          <div id={laneId+'-'+x_id+'-Down'} className="subLaneClass restLaneClass" style={laneStyleDown}></div>
          <div id={laneId+'-'+x_id+'-Down-Hlt'} className="subLaneClass" style={laneStyleDown}></div>
          <div id={laneId+'-'+x_id+'-Resize'} className="subLaneClass resizeLaneAnchor" style={laneStyleResize} onMouseDown={handleMouseDown}></div>
        </React.Fragment>
      }

      else if (isChildLaneFunc(laneId)) {
        let childPosition =[position[0] + 20,laneOrdinate];
        let childLength = length - 20;
        laneOrdinate += findLaneHeight(laneId,globalLanes);
        let slane = new swimLanePropsA( globalLanes[laneId].title, childPosition, childLength, globalLanes[laneId].laneIds);

        return <SLaneAnchorComp key={index} x_id={laneId} slane={slane} insertTextFunc={insertTextFunc} handleMouseDown={handleMouseDown}/>        
      }

      else {
        console.error(`invalid id : ${laneId}`);
        return null
      }

    }) ;
  

  return (
    <>
      {IsParentLane && <div  id={x_id + "-panel"} className="subLaneClass swimLanePanelClass" style={swimLanePanel} ></div>}
      <div id={x_id} className={`subLaneClass laneTxtCls restLaneClass titleLaneAnchor`} style={swimLaneTitleStyle} onMouseDown={handleMouseDown} onDoubleClick={()=>{!IsParentLane && insertTextFunc(x_id)}}><div className="vertical-text">{title}</div></div>
      <div id={x_id + "-Hlt"} className="subLaneClass" style={swimLaneTitleStyleHlt} ></div>
      {IsParentLane && <div  className="subLaneClass opaqueBgClass" style={swimLaneTitleStyleOpaque} ></div>}
      {!IsParentLane && <>
        <div id={x_id + "-Up"} className="subLaneClass restLaneClass" style={swimLaneTitleStyleUp} ></div>
        <div id={x_id + "-Up-Hlt"} className="subLaneClass" style={swimLaneTitleStyleUpHlt} ></div>
      </>}
      {!IsParentLane && <>
        <div id={x_id + "-Down"} className="subLaneClass restLaneClass" style={swimLaneTitleStyleDown} ></div>
        <div id={x_id + "-Down-Hlt"} className="subLaneClass" style={swimLaneTitleStyleDownHlt} ></div>
      </>}
      {subLaneAnchors}
    </>
  )
}

export default SLaneAnchorComp;
