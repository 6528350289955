import { useMemo} from "react";
import { baseLineStyle, calcAngleDegrees, calcDistance, crclHeadStyle, crclPointStyle, infoBoxStyle } from "./Mn_connector_func";

const ConnectorAnchors2 = function ({id, lineStyle, handleMouseEnter, handleMouseLeave, handleMouseDown, handleTouch}){

        let points = useMemo(() => [...lineStyle['pts']], [lineStyle]);

        let len2 = calcDistance(points[0], points[1]);
        let angl2 = calcAngleDegrees(points[0], points[1]);
        let trnsfrm2 = `rotate(${angl2}deg)`;
        
        return (
            <div className="connector-parent" id={id} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseDown={handleMouseDown} >
                <div className="baselineAnchor baseLine" id={'bsLine-' + id + '-0'} style={baseLineStyle(points[0], len2, trnsfrm2)} ></div>
                <div className="crclAnchor crclTail" id={'crclTail-' + id + '-0'} style={crclPointStyle(points[0])} ></div>
                <div className="crclAnchor crclHead" id={'crclHead-' + id + '-1'} onMouseEnter={() => {handleTouch(infoBoxStyle(points[1]))}} style={crclHeadStyle(points[1], trnsfrm2)} ></div>   
            </div>
    )}

export default ConnectorAnchors2;